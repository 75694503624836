import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import TextInput from "../../components/TextInput/TextInput";
import "./personalinfo.css";
import { postUserDetils, userDetail } from "../../api/modules/loothoot.class";
import Toaster from "../Toast/Toast";
const PersonalInfo = () => {
  const userid = useSelector((state) => state?.auth?.user?.id);
  const [shipping, setShipping] = useState();
  const [error, setError] = useState({ success: "", error: "" });
  const isDarkMode = useSelector((state) => state?.theme?.darkMode);

  const initialValues = {
    username: shipping?.userdetails?.username || "",
    email: shipping?.email || "",
  };

  const SignupSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email address").required("Required"),
    username: Yup.string().required("Enter your name"),
  });
  const handleSubmit = (values) => {
    try {
      const api = async () => {
        const response = await postUserDetils({
          ...values,
        });
        setError((prev) => ({
          ...prev,
          success: response?.data?.message || "Record updated successfully!",
          error: response?.data?.error?.message || "",
        }));
      };

      api();
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    try {
      const api = async () => {
        const response = await userDetail(userid);

        setShipping(response?.data?.data);
      };
      api();
    } catch (err) {
      console.log(err);
    }
  }, []);
  return (
    <>
      <Toaster message={error} />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={SignupSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form className="personal-info-form">
            <h2>Personal Information</h2>
            <div className="personal-info-input-feilds">
              <div className="loginElement">
                <TextInput
                  isDarkMode={isDarkMode}
                  type="email"
                  name="email"
                  placeholder="Email address"
                  onChange={handleChange}
                  value={values?.email}
                />

                <span className="error">
                  {errors.email && touched.email && errors.email}
                </span>
              </div>

              <div className="loginElement">
                <TextInput
                  isDarkMode={isDarkMode}
                  type="text"
                  name="username"
                  placeholder="Name"
                  onChange={handleChange}
                  value={values?.username}
                />
                <span className="error">
                  {errors.username && touched.username && errors.username}
                </span>
              </div>
            </div>
            <button className="save-info-button" type="submit">
              <span>Save Information</span>
            </button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default PersonalInfo;
