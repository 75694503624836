import React from "react";
import { useSelector } from "react-redux";

import battle1 from "../../assets/images/Battles/battle1.png";
import battle2 from "../../assets/images/Battles/battle2.png";

const BattleUSer = ({ player, creator }) => {
  const isDarkMode = useSelector((state) => state.theme.darkMode);
  const user = useSelector((state) => state?.auth?.user);
  return (
    <div>
      <div className="Battle-Footer-Section1-main">
        <div className="user-icon">
          <img
            src={
              player?.user?.userdetails?.avatar
                ? `${process.env.REACT_APP_CDN_URL}/images/${player?.user?.userdetails?.avatar}`
                : creator
                ? battle1
                : battle2
            }
            alt=""
            srcset=""
          />
        </div>
        <div className="Battle-Footer-Section1-des">
          <h6>
            {player?.user?.id === user?.id
              ? "MY WINNINGS"
              : `${player?.user?.userdetails?.username}'s WINNINGS` ||
                `Avatar-${player?.user?.id}'s WINNINGS`}
          </h6>
          <span>${player?.total_amount}</span>
        </div>
      </div>
      <div className="battle-footer-cards-main">
        {player?.total_products?.map((item, index) =>
          item != null ? (
            <div
              className={
                isDarkMode
                  ? "battle-footer-card battle-footer-card-dark"
                  : "battle-footer-card battle-footer-card-light"
              }
            >
              <div className="d-flex justify-content-center p-1">
                <img
                  src={`${process.env.REACT_APP_CDN_URL}/images/${item?.image}`}
                  alt=""
                />
              </div>
              <div className="d-flex justify-content-center footer-card-end">
                {item?.name}
              </div>
              <div className="d-flex justify-content-center">
                <span className="battle-card-item-price">${item?.price}</span>
              </div>
            </div>
          ) : (
            <div className="battle-dummy-card"> Round {index + 1} </div>
          ),
        )}
      </div>
    </div>
  );
};

export default BattleUSer;
