import React, { useEffect, useState } from "react";
import "./battleDetail.css";
import BattleRoulete from "./BattleRoulete";
import BattleHeader from "./BattleHeader";
import { useParams } from "react-router-dom";
import { getBattlesById } from "../../api/modules/loothoot.class";
import FullAppLoader from "../Loader/FullAppLoader";
import { useDispatch, useSelector } from "react-redux";
import { toggleLoader } from "../../redux/slices/loaderSlice";
import BattleFooter from "./BattleFooter";
import BattleEnd from "./BattleEnd";
import BattleWait from "./BattleWait";
import CoinFlip from "./CoinFlip";
import Countdown from "../countDown/CountDown";
import PageNotFound from "../BattleNotFound/BattleNotFound";

const BattleDetail = () => {
  const { slugId } = useParams();
  const isLoading = useSelector((state) => state?.loader?.isLoading);
  const user = useSelector((state) => state?.auth?.user);
  const [battle, setBattle] = useState(null);
  const [joinPrivate, setJoinPrivate] = useState(false);
  const [showOtherComponent, setShowOtherComponent] = useState(false);
  const [initialCallDone, setInitialCallDone] = useState(false);

  const decryptData = () => {
    try {
      const decodedData = parseInt(window.atob(slugId));
      return decodedData;
    } catch (error) {
      console.error("Decryption error:", error);
    }
  };

  const countDown = JSON.parse(
    sessionStorage.getItem(`countDown-${JSON.stringify(decryptData())}`),
  );

  const handleCountdownComplete = () => {
    setShowOtherComponent(true);
    sessionStorage.setItem(
      `countDown-${JSON.stringify(decryptData())}`,
      JSON.stringify(false),
    );
  };
  const dispatch = useDispatch();

  const api = async () => {
    const decryptedData = JSON.stringify(decryptData());
    try {
      const response = await getBattlesById({
        battle_id: decryptedData,
        user_id: user?.id,
      });
      if (response?.success === false) {
        setBattle(null);
      } else {
        setBattle(response?.data);
      }
      dispatch(toggleLoader({ isLoading: false }));
      if (response?.message?.includes("500")) {
        sessionStorage.setItem(
          `countDown-${decryptedData}`,
          JSON.stringify(true),
        );
      }
    } catch (error) {
      dispatch(toggleLoader({ isLoading: false }));
      console.log(error);
    }
  };

  useEffect(() => {
    if (!initialCallDone && !countDown) {
      dispatch(toggleLoader({ isLoading: true }));
      api();
      setInitialCallDone(true);
    }

    const intervalCall = setInterval(
      () => {
        if (!battle?.user_1?.winner && !battle?.user_2?.winner) {
          api("other");
        }
      },
      battle === null ? 1000 : battle?.flip !== true ? 4000 : 10000,
    );

    return () => {
      clearInterval(intervalCall);
    };
  }, [battle, slugId, joinPrivate]);

  return isLoading ? (
    <FullAppLoader />
  ) : user?.id === battle?.user_1?.user?.id ||
    user?.id === battle?.user_2?.user?.id ||
    battle === null ? (
    !showOtherComponent && countDown ? (
      <Countdown onComplete={handleCountdownComplete} height={"100vh"} />
    ) : (
      <>
        {!battle?.exception ? (
          <BattleHeader battleDetail={battle?.box_detail} />
        ) : null}
        {battle?.flip ? (
          <CoinFlip
            player1={battle?.user_1}
            player2={battle?.user_2}
            battle_id={JSON.stringify(decryptData())}
            setBattle={setBattle}
          />
        ) : null}
        {battle?.user_1?.winner || battle?.user_2?.winner ? (
          <BattleEnd player1={battle?.user_1} player2={battle?.user_2} />
        ) : battle?.box_products ? (
          <BattleRoulete
            products={battle?.box_products}
            player1={battle?.user_1}
            player2={battle?.user_2}
            boxCount={battle?.box_detail?.current_box}
          />
        ) : null}
        {battle?.user_1?.user?.id && battle?.user_2?.user?.id ? (
          <BattleFooter player1={battle?.user_1} player2={battle?.user_2} />
        ) : null}
      </>
    )
  ) : battle?.user_1?.id && !battle?.user_2?.id ? (
    <BattleWait battle={battle} setJoinPrivate={setJoinPrivate} />
  ) : (
    <PageNotFound />
  );
};

export default BattleDetail;
