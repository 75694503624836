import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { getCart, sellItem } from "../../api/modules/loothoot.class";
import Toaster from "../../components/Toast/Toast";

const MultiSelect = (props) => {
  const isDarkMode = useSelector((state) => state.theme.darkMode);
  const user = useSelector((state) => state?.auth?.user);
  const [error, setError] = useState({ success: "", error: "" });
  const [totalPrice, setTotalPrice] = useState(0);
  const [selectedItemIndexes, setSelectedItemIndexes] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    const newTotalPrice = selectedItemIndexes.reduce(
      (total, index) => total + parseFloat(props.data[index]?.price),
      0,
    );
    setTotalPrice(newTotalPrice);
  }, [selectedItemIndexes, props.data]);
  const handleCardClick = (_, index) => {
    const isItemSelected = selectedItemIndexes.includes(index);

    if (isItemSelected) {
      setSelectedItemIndexes(
        selectedItemIndexes.filter((idx) => idx !== index),
      );
      setSelectAll(false);
    } else {
      setSelectedItemIndexes([...selectedItemIndexes, index]);
      if (selectedItemIndexes.length + 1 === props.data.length) {
        setSelectAll(true);
      }
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedItemIndexes([]);
      setSelectAll(false);
    } else {
      setSelectedItemIndexes([...Array(props.data.length).keys()]);
      setSelectAll(true);
    }
  };

  const handleSell = async () => {
    try {
      const sellIds = selectedItemIndexes.map(
        (index) => props?.data[index]?.id,
      );
      const response = await sellItem({ product_id: sellIds });

      setError((prev) => ({
        ...prev,
        success: response?.data?.message || "",
        error: response?.data?.error?.message || "",
      }));

      if (response?.data?.success) {
        const response = await getCart(user?.id);
        props?.setCart(response?.data?.data);
        setSelectedItemIndexes([]); // Clear the selected item indexes after successful sell
        setSelectAll(false); // Unselect all items
      }

      props.onHide();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={isDarkMode ? "modal-content-dark" : "modal-content-light"}
    >
      <Toaster message={error} />
      <Modal.Body>
        <div className="withdraw-bitcoin-section2 mt-1">
          <div>
            <h6
              className={
                isDarkMode ? "deposit-heading-dark" : "deposit-heading-light"
              }
            >
              select items to Sell
            </h6>
          </div>

          <div>
            <h6
              className={
                isDarkMode
                  ? "deposit-heading-dark selectall-txt"
                  : "deposit-heading-light selectall-txt"
              }
              onClick={handleSelectAll}
            >
              {selectAll ? "Unselect all" : "Select all"}
              ($
              {totalPrice?.toFixed(2)})
            </h6>
          </div>
        </div>
        <div className="card-main-withdraww">
          {props?.data?.map((item, index) => (
            <Card
              onClick={() => handleCardClick(item, index)}
              className={
                isDarkMode
                  ? "battles-main-dark WithdrawCard"
                  : " battles-main-light WithdrawCard"
              }
            >
              <div className=" withdraw-card-head">
                <div className="d-flex justify-content-end">
                  <input
                    type="checkbox"
                    checked={selectedItemIndexes.includes(index)}
                  />
                </div>
                <div className="withdraw-card-img">
                  <img
                    variant="top"
                    src={`${process.env.REACT_APP_CDN_URL}/images/${
                      item?.box_image ? item?.box_image?.image : item?.image
                    }`}
                    alt=" "
                    className="card-img-withdraw"
                  />
                </div>
              </div>
              <Card.Body>
                <Card.Title className="withdraw-card-title">
                  ${item?.price} Voucher
                </Card.Title>
                <Card.Text className="withdraw-card-text">
                  ${item?.price}
                </Card.Text>
              </Card.Body>
            </Card>
          ))}
        </div>
      </Modal.Body>
      <div className="withdraw-bitcoin-footer mt-2">
        <div
          className={
            isDarkMode
              ? "deposit-heading-dark withdraw-bitcoin-footer-div p-3"
              : "deposit-heading-light withdraw-bitcoin-footer-div p-3"
          }
        >
          {selectedItemIndexes?.length} items selected - Total $
          {totalPrice?.toFixed(2)}
        </div>
      </div>
      <div className="mb-2 d-flex justify-content-center align-items-center gap-2">
        <button
          className="myaccount-menu-btn mx-2"
          onClick={() =>
            handleSell(
              selectedItemIndexes.map((index) => props.data[index]?.id),
            )
          }
          disabled={!selectedItemIndexes.length}
        >
          <span> Sell</span>
        </button>

        <button className="myaccount-menu-btn mx-2" onClick={props.onHide}>
          <span> Close</span>
        </button>
      </div>
    </Modal>
  );
};
export default MultiSelect;
