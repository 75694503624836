import React, { useEffect } from "react";
import "./countdown.scss"; // Import your CSS file for styles
import { useSelector } from "react-redux";

const Countdown = ({ onComplete, height }) => {
  const isDarkMode = useSelector((state) => state.theme.darkMode);

  useEffect(() => {
    setTimeout(() => {
      onComplete();
    }, 6000);
  }, [onComplete]);

  return (
    <div
      className={
        isDarkMode
          ? " container-countdown-dark container-countdown"
          : "container-countdown"
      }
      style={{ height: height }}
    >
      <span className={isDarkMode ? " spanText-dark spanText" : "spanText"}>
        3
      </span>
      <span className={isDarkMode ? " spanText-dark spanText" : "spanText"}>
        2
      </span>
      <span className={isDarkMode ? " spanText-dark spanText" : "spanText"}>
        1
      </span>
      <span className={isDarkMode ? " spanText-dark spanText" : "spanText"}>
        Play
      </span>
    </div>
  );
};

export default Countdown;
