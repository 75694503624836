import React from "react";
import { useSelector } from "react-redux";
import MoonLoader from "react-spinners/MoonLoader";
import logoLight from "../../assets/images/logoLight.png";
import logoDark from "../../assets/images/logoDark.png";

const FullAppLoader = () => {
  const isDarkMode = useSelector((state) => state?.theme?.darkMode);

  return (
    <div
      className={
        isDarkMode
          ? "full-app-loader full-app-loader-dark "
          : "full-app-loader full-app-loader-light"
      }
    >
      <img src={logoLight} width={125} />
      <MoonLoader color="#e30613" />
    </div>
  );
};

export default FullAppLoader;
