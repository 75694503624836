import React, { useEffect, useState } from "react";
import "./home.css";
import HomePageCarousel from "../../components/homepagecarousel/HomePageCarousel";
import Benefits from "../../components/Benefits/Benefits";
import FreshDeliveries from "../../components/FreshDeliveries/FreshDeliveries";
import ProductCarousel from "../../components/ProductCarousel/ProductCarousel";
import { boxCategory } from "../../api/modules/loothoot.class";

const Home = (props) => {
  const [loading, setLoading] = useState(true);
  //featured category
  const [numSlide, setNumslide] = useState();
  const [numSlideupd, setNumslideupd] = useState();
  const [numSlidehot, setNumslidehot] = useState();
  const [featuredData, setfeaturedData] = useState();
  const [updatedData, setupdatedData] = useState();
  const [hotData, sethotData] = useState();

  useEffect(() => {
    try {
      const api = async () => {
        const response = await boxCategory("Featured");
        setfeaturedData(response?.data?.data?.data[0]);
        setLoading(false);
        setNumslide(response?.data?.data?.data[0]?.box?.length);

        const updatedresponse = await boxCategory("Updated");
        setupdatedData(updatedresponse?.data?.data?.data[0]);
        setLoading(false);
        setNumslideupd(updatedresponse?.data?.data?.data[0]?.box?.length);

        const hotResponse = await boxCategory("Hot");
        sethotData(hotResponse?.data?.data?.data[0]);
        setLoading(false);
        setNumslidehot(hotResponse?.data?.data?.data[0]?.box?.length);
      };

      api();
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }, []);

  return (
    <>
      <HomePageCarousel />

      <ProductCarousel
        icon="&#x2B50"
        cardData={featuredData}
        loading={loading}
        setLoading={setLoading}
        numSlide={numSlide}
      />
      <ProductCarousel
        icon="&#x1F514"
        cardData={updatedData}
        loading={loading}
        setLoading={setLoading}
        numSlide={numSlideupd}
      />
      <ProductCarousel
        icon="&#x1F525"
        cardData={hotData}
        loading={loading}
        setLoading={setLoading}
        numSlide={numSlidehot}
      />
      {/* <Battles /> */}
      <Benefits />
      <FreshDeliveries />
    </>
  );
};

export default Home;
