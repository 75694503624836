import React, { useEffect, useState } from "react";
import "./top-ups.css";
import { useSelector } from "react-redux";
import MyaccountList from "../../components/myaccount-lists/MyaccountList";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/slices/authSlice";
import { Topup } from "../../api/modules/loothoot.class";
import Loading from "../../components/Loader/Loading";

const MyAccount = () => {
  const dispatch = useDispatch();
  const [topup, setTopup] = useState();
  const [loading, setLoading] = useState(true);

  const handleLogout = () => {
    dispatch(logout());
  };
  const isDarkMode = useSelector((state) => state.theme.darkMode);

  useEffect(() => {
    try {
      const api = () => {
        const res = Topup();
        res
          .then((result) => {
            setTopup(result?.data?.data);
            setLoading(false);
          })
          .catch((err) => {
            console.log("err", err);
          });
      };
      api();
    } catch (error) {
      console.log(error);
    }
  }, []);
  return (
    <>
      {/* <Dashboard /> */}
      <MyaccountList />
      {/* <div className="d-flex justify-content-end">
        <button className="hidefilterbutton" onClick={toggleVisibility}>
          <RiArrowDropDownLine />
          {isVisible ? "Hide Filter" : "Show Filter"}
        </button>
      </div>
      {isVisible && (
        //
        <Formik
          initialValues={initialValues}
          validationSchema={FilterSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form className="personal-info-form">
              <div className="topup-input-feilds">
                <div className="loginElement">
                  <label
                    className={
                      isDarkMode ? "label label-dark" : " label label-light"
                    }
                  >
                    TYPES
                  </label>
                  <CustomSelect options={topupOptions} />
                </div>

                <div className="loginElement">
                  <TextInput
                    isDarkMode={isDarkMode}
                    type="date"
                    label="MINDATE"
                    name="min_date"
                    onChange={handleChange}
                    value={values.password}
                  />
                </div>
                <div className="loginElement">
                  <TextInput
                    isDarkMode={isDarkMode}
                    type="date"
                    label="MAXDATE"
                    name="max_date"
                    onChange={handleChange}
                    value={values.password}
                  />
                </div>
              </div>
              <div className="topup-buttons">
                <Button bsPrefix="filter-button">
                  <span>Filter</span>
                </Button>
                <Button bsPrefix="clear-button">
                  <span>Clear</span>
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )} */}
      <div className="loader-icon">{loading && <Loading />}</div>
      <div className=" topup-table">
        <table className={isDarkMode ? "table table-dark" : "table"}>
          <thead>
            <tr className="tablerow">
              <th>ID</th>
              <th>CREATED</th>
              <th>UPDATED</th>
              <th>PROVIDER</th>
              <th>TYPE</th>
              <th>STATUS</th>
              <th>AMOUNT</th>
            </tr>
          </thead>

          <tbody>
            {topup?.map((item, index) => (
              <tr className="tablerow" key={index}>
                <td className="tablehead">{item?.txid}</td>
                <td className="tablehead">{item?.created_at}</td>
                <td className="tablehead">{item?.updated_at}</td>
                <td className="tablehead">{item?.chain_id}</td>
                <td className="tablehead">{item?.opration_key}</td>
                <td className="tablehead">{item?.status}</td>
                <td className="tablehead">{item?.amount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="logout-button-section mt-5">
        <button className="logout-button" onClick={handleLogout}>
          <span>log out</span>
        </button>
      </div>
    </>
  );
};

export default MyAccount;
