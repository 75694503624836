import React from "react";

const Blog = () => {
  return (
    <>
      <h1 className="text-center p-3">Blog</h1>
    </>
  );
};

export default Blog;
