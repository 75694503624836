const endpoints = {
  login: "/login",
  register: "/register",
  allBoxes: "/boxes",
  boxCategory: "/box",
  boxProducts: "/box-products",
  postUserDetils: "/user-details",
  userDetail: "/show-user-detail",
  spinWheel: "/spin-wheel",
  spinFree: "/spin-free",
  addCart: "/add-cart",
  getCart: "/show-cart",
  sellItem: "/sell-items",
  getAllBattles: "/show-all-battle",
  getAllBattlesByUser: "/logged-in-battles",
  getBattlesById: "/show-battle",
  postBattles: "/battles",
  ContactUs: "/contact-us",
  getCoins: "/payments/coins",
  getWalletAddress: "/payments/get-address",
  WithDraw: "/payments/withdraw",
  ExchangeRate: "//payments/exchange-rate",
  FooterLinks: "/footer-social-link",
  GetBalance: "/get-accounts",
  shipItems: "/shipment",
  faq: "/faq",
  terms: "/term-condition",
  privacy: "/privacy-statment",
  provably: "/provably-fair",
  cookie: "/cookie-policy",
  policy: "/aml-policy",
  joinBattle: "/battle-join",
  Document: "/user-document",
  GetDocument: "/get-user-document",
  GetTransaction: "/show-transaction-history",
  Fairness: "/get-user-seed",
  PostSeed: "/update-user-seed",
  Topup: "/topup",
  battleCancel: "/cancel-battle",
  Avatar: "/user-avatar",
  FlipCoin: "/flip-coin-result",
  battlePlayTrigger: "/battle-play",
  FlipStatus: "/flip-status-change",
  GetFooterLogo: "/footer-logo",
  ForgetPassword: "/user-forget-password",
  Slider: "/slider",
  reset: "/reset",
};

export default endpoints;
