import React from "react";
import MyaccountList from "../../components/myaccount-lists/MyaccountList";
import "./quickupgrade.css";
import { Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/slices/authSlice";
const MyAccount = () => {
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout());
  };
  return (
    <>
      {/* <Dashboard /> */}
      <MyaccountList />
      <div className="  topup-table">
        <Table striped>
          <thead>
            <tr className="tablerow">
              <th className="tablehead">ID</th>
              <th className="tablehead">FAIRNESS</th>
              <th className="tablehead">WHEN</th>
              <th className="tablehead">BET</th>
              <th className="tablehead">MULTIPLIER</th>
              <th className="tablehead">GAME</th>
              <th className="tablehead">ROLL</th>
              <th className="tablehead">PROFIT</th>
            </tr>
          </thead>
          <tbody></tbody>
        </Table>
      </div>
      <div className="logout-button-section mt-5">
        <button className="logout-button" onClick={handleLogout}>
          <span>log out</span>
        </button>
      </div>
    </>
  );
};

export default MyAccount;
