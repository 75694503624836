import React, { useState } from "react";

const CopyToClipboardButton = () => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = async () => {
    const currentPath = window.location.href;
    try {
      await navigator.clipboard.writeText(currentPath);
      setCopied(true);
      // Reset the copied state after a brief period (optional)
      setTimeout(() => setCopied(false), 1500);
    } catch (error) {
      console.error("Failed to copy text: ", error);
    }
  };

  return (
    <button className="playwithbot" onClick={copyToClipboard}>
      {copied ? "Link copied!" : "Share battle link"}
    </button>
  );
};

export default CopyToClipboardButton;
