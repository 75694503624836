import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./myaccountlist.css";
const MyaccountList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  //listing
  const items = [
    { name: "Account", path: "/myaccount" },
    // { name: "Stats", path: "/myaccount/stats" },
    { name: "Cart", path: "/myaccount/cart" },
    { name: "Avatar", path: "/myaccount/avatar" },
    { name: "Documents", path: "/myaccount/documents" },
    // { name: "Quick Upgrade", path: "/myaccount/quick-upgrade" },
    { name: "Fairness", path: "/myaccount/fairness" },
    // { name: "GameHistory", path: "/myaccount/game/history" },
    { name: "Transactions", path: "/myaccount/transactions" },
    { name: "TOPUPS", path: "/myaccount/top-ups" },
    // { name: "Promo-code", path: "/myaccount/promo" },
  ];

  return (
    <>
      <div className="myaccount-menu mt-4">
        {items.map((item, index) => (
          <div key={index}>
            <button
              className={`myaccount-menu-btn list-group-item-${
                location?.pathname === item.path ? "active" : ""
              }`}
              onClick={() => navigate(item.path)}
            >
              <span>{item.name}</span>
            </button>
          </div>
        ))}
      </div>
    </>
  );
};

export default MyaccountList;
