import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { routes } from "./routes";
import { getCookie } from "./services/cookies.service";

const ProtectedRoutes = ({ children }) => {
  const navigate = useNavigate();
  const token = getCookie("token");
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  if (!token && !isLoggedIn) {
    return navigate(routes.HOME);
  }

  return <>{children}</>;
};

export default ProtectedRoutes;
