import React, { useState } from "react";
import { useEffect } from "react";
import { Card, Button } from "react-bootstrap";
import { useSelector } from "react-redux";

const WithdrawCard = ({
  data,
  setClickedDiv,
  setSelectedItems,
  selectedItems,
}) => {
  const isDarkMode = useSelector((state) => state.theme.darkMode);
  const [totalPrice, setTotalPrice] = useState();
  const [selectAll, setSelectAll] = useState(false);
  const [selectedItemIndexes, setSelectedItemIndexes] = useState([]);

  const handleCardClick = (item, index) => {
    const isItemSelected = selectedItemIndexes.includes(index);
    if (isItemSelected) {
      setSelectedItemIndexes(
        selectedItemIndexes.filter((idx) => idx !== index),
      );
      setSelectAll(false);
    } else {
      setSelectedItemIndexes([...selectedItemIndexes, index]);
      if (selectedItemIndexes.length + 1 === data?.length) {
        setSelectAll(true);
      }
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedItemIndexes([]);
    } else {
      setSelectedItemIndexes(data.map((_, index) => index));
    }
    setSelectAll(!selectAll);
  };

  const handleDivClick = (divName, selectedItemIndexes) => {
    // Convert selected indexes to selected items and set the selectedItems state
    const selectedItems = selectedItemIndexes.map((index) => data[index]);
    setSelectedItems(selectedItems);
    setClickedDiv(divName, selectedItems);
  };

  useEffect(() => {
    const newTotalPrice = selectedItemIndexes.reduce(
      (total, index) => total + parseFloat(data[index]?.price),
      0,
    );
    setTotalPrice(newTotalPrice);
  }, [selectedItemIndexes, data]);

  return (
    <>
      <div className="withdraw-bitcoin-section2 mt-1">
        <div>
          <h6
            className={
              isDarkMode ? "deposit-heading-dark" : "deposit-heading-light"
            }
          >
            select items to withdraw
          </h6>
        </div>

        <div>
          <h6
            className={
              isDarkMode
                ? "deposit-heading-dark selectall-txt"
                : "deposit-heading-light selectall-txt"
            }
            onClick={handleSelectAll}
          >
            {selectAll ? "Unselect all" : "Select all"}
            ($
            {totalPrice?.toFixed(2)})
          </h6>
        </div>
      </div>
      <div className="card-main-withdraww">
        {data?.map((item, index) => (
          <Card
            onClick={() => handleCardClick(item, index)}
            className={
              isDarkMode
                ? "battles-main-dark WithdrawCard"
                : " battles-main-light WithdrawCard"
            }
          >
            <div className=" withdraw-card-head">
              <div className="d-flex justify-content-end">
                <input
                  type="checkbox"
                  checked={selectedItemIndexes.includes(index)}
                />
              </div>
              <div className="withdraw-card-img">
                <img
                  variant="top"
                  src={`${process.env.REACT_APP_CDN_URL}/images/${
                    item?.box_image ? item?.box_image?.image : item?.image
                  }`}
                  alt=" "
                  className="card-img-withdraw"
                />
              </div>
            </div>
            <Card.Body>
              <Card.Title className="withdraw-card-title">
                ${item?.price} Voucher
              </Card.Title>
              <Card.Text className="withdraw-card-text">
                ${item?.price}
              </Card.Text>
            </Card.Body>
          </Card>
        ))}
      </div>
      <div className="withdraw-bitcoin-footer mt-2">
        <div
          className={
            isDarkMode
              ? "deposit-heading-dark withdraw-bitcoin-footer-div"
              : "deposit-heading-light withdraw-bitcoin-footer-div"
          }
        >
          {selectedItemIndexes?.length} items selected - Total $
          {totalPrice?.toFixed(2)}
        </div>
        <Button
          bsPrefix="withdraw-next"
          onClick={() => handleDivClick("next", selectedItemIndexes)}
        >
          Next
        </Button>
      </div>
    </>
  );
};

export default WithdrawCard;
