import React from "react";
import "./benefits.css";
import varietybox from "../../assets/icons/varietybox.png";
import shipping from "../../assets/icons/shipping.png";
import gurantee from "../../assets/icons/gurantee.png";
import { useSelector } from "react-redux";

const Benefits = () => {
  const isDarkMode = useSelector((state) => state.theme.darkMode);
  return (
    <>
      <div
        className={isDarkMode ? "benefits-main-dark" : "benefits-main-light"}
      >
        <div className="benefits-sections">
          <div className="varietybox-div">
            <img src={varietybox} alt="" />
            <h6>Variety Of Boxes</h6>
            <p>
              From sneakers to streetwear, technology and beyond, your ideal box
              is only a click away.
            </p>
          </div>
          <div className="varietybox-div">
            <img src={shipping} alt="" />
            <h6>Fast Shipping</h6>
            <p>
              At the hands of our speedy shipping team, your package will arrive
              before you know it.
            </p>
          </div>
          <div className="varietybox-div">
            <img src={gurantee} alt="" />
            <h6>Guaranteed Value</h6>
            <p>
              From verified partners, your premium item is brand authenticated
              every time.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Benefits;
