export const RouletteProcessedData = async (products, productId) => {
  try {
    let prizeIndex = -1;
    const prizes = await products?.map((item) => {
      return {
        ...item,
        image: `${process.env.REACT_APP_CDN_URL}/images/${item?.image}`,
        text: item?.name,
        user_id: item?.id,
      };
    });

    const reproductionArray = (array = [], length = 0) => [
      ...Array(length)
        .fill("_")
        .map(() => array[Math.floor(Math.random() * array.length)]),
    ];

    const reproducedPrizeList = [
      ...prizes,
      ...reproductionArray(prizes, prizes.length * 3),
      ...prizes,
      ...reproductionArray(prizes, prizes.length),
    ];

    const generateId = () =>
      `${Date.now().toString(36)}-${Math.random().toString(36).substring(2)}`;

    const prizeList = reproducedPrizeList.map((prize) => ({
      ...prize,
      id:
        typeof crypto.randomUUID === "function"
          ? crypto.randomUUID()
          : generateId(),
    }));

    const productItem = products?.find((item) => item.id === productId);
    if (productItem) {
      const indexInPrizeList = await prizeList?.findIndex(
        (item) => item.user_id === productItem.id,
      );
      if (indexInPrizeList !== -1) {
        prizeIndex = prizes.length * 4 + indexInPrizeList;
        console.log(
          `Product with id ${productId} found at index ${indexInPrizeList}.`,
        );
      } else {
        console.log(`Product with id ${productId} not found.`);
      }
    } else {
      console.log(`Product with id ${productId} not found.`);
      prizeIndex = -1;
    }

    return { list: prizeList, index: prizeIndex };
  } catch (error) {
    return { success: false, message: error.message };
  }
};
