// import Slider from "react-slick";
// import React, { useState } from "react";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// // import { Card } from "react-bootstrap";
// import "./careousal.css";
// import { useResponsive } from "./useResponsive";
// import { useRef } from "react";
// import { useSelector } from "react-redux";
// import arrowback from "../../assets/images/arrowback.png";
// import arrowbackdark from "../../assets/images/arrowbackdark.png";
// import arrownext from "../../assets/images/arrownext.png";
// import Card from "../../components/Card/Card";
// export const SliderComp = ({
//   slideNum,
//   autoplay,
//   autoplaySpeed,
//   cardData,
//   setShowOverlay,
//   showOverlay,
//   spinner,
//   setoverlayData,
// }) => {

//   const { screenType } = useResponsive();
//   const sliderRef = useRef();

//   const settings = {
//     arrows: false,
//     dots: true,
//     swipeToSlide: true,
//     infinite: true,
//     autoplay: autoplay,
//     autoplaySpeed: autoplaySpeed,
//     slidesToScroll: 1,
//     slidesToShow:
//       screenType === "MOBILE"
//         ? 1
//         : screenType === "TABLET"
//         ? slideNum === 1
//           ? 1
//           : 2
//         : screenType === "LAPTOP"
//         ? slideNum === 1
//           ? 1
//           : slideNum === 2
//           ? 2
//           : 3
//         : screenType === "DESKTOP"
//         ? slideNum < 4
//           ? slideNum
//           : 4
//         : screenType === "DESKTOPLG"
//         ? slideNum < 4
//           ? slideNum
//           : 5
//         : slideNum,
//   };

//   const isDarkMode = useSelector((state) => state.theme.darkMode);

//   return (
//     <>
//       <Slider {...settings} ref={sliderRef} className="slidermain">
//         {cardData?.box?.map((item, index) => (
//           <Card
//             spinner={spinner}
//             item={item}
//             type={cardData?.name}
//             index={index}
//             setShowOverlay={setShowOverlay}
//             showOverlay={showOverlay}
//             setoverlayData={setoverlayData}
//           />
//         ))}
//       </Slider>

//       <div className="sliderButtonsMain">
//         <div
//           onClick={() => sliderRef.current.slickPrev()}
//           className={
//             isDarkMode
//               ? "slider-prev-button slider-prev-button-dark"
//               : "slider-prev-button slider-prev-button-light"
//           }
//         >
//           <img src={isDarkMode ? arrowbackdark : arrowback} alt="" />
//         </div>

//         <div
//           onClick={() => sliderRef.current.slickNext()}
//           className="slider-next-button"
//         >
//           <img src={arrownext} alt="" />
//         </div>
//       </div>
//     </>
//   );
// };
import React from "react";
import "./careousal.css";
import { useSelector } from "react-redux";
import arrowback from "../../assets/images/arrowback.png";
import arrowbackdark from "../../assets/images/arrowbackdark.png";
import Card from "../../components/Card/Card";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { ChevronRight } from "lucide-react";

export const SliderComp = ({
  cardData,
  setShowOverlay,
  showOverlay,
  spinner,
  setoverlayData,
}) => {
  const responsive = {
    superLargeDesktopproMax: {
      breakpoint: { max: 4000, min: 2500 },
      items: 7,
    },
    superLargeDesktoppro: {
      breakpoint: { max: 2500, min: 2000 },
      items: 6,
    },
    superLargeDesktop: {
      breakpoint: { max: 2000, min: 1500 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 1500, min: 1000 },
      items: 4,
    },
    laptop: {
      breakpoint: { max: 1000, min: 730 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 730, min: 425 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 425, min: 0 },
      items: 1,
    },
  };
  const isDarkMode = useSelector((state) => state?.theme?.darkMode);
  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide },
    } = rest;
    return (
      <div className="carousel-button-group">
        <div className="sliderButtonsMain">
          <div
            onClick={() => previous()}
            className={
              isDarkMode
                ? "slider-prev-button slider-prev-button-dark"
                : "slider-prev-button slider-prev-button-light"
            }
          >
            <img src={isDarkMode ? arrowbackdark : arrowback} alt="" />
          </div>

          <div onClick={() => next()} className="slider-next-button">
            <ChevronRight style={{ color: "white" }} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {cardData?.box?.length && (
        <Carousel
          responsive={responsive}
          infinite={true}
          removeArrowOnDeviceType={[
            "tablet",
            "mobile",
            "desktop",
            "superLargeDesktop",
            "superLargeDesktoppro",
            "superLargeDesktopproMax",
            "laptop",
          ]}
          renderButtonGroupOutside={true}
          customButtonGroup={<ButtonGroup />}
        >
          {cardData?.box?.map((item, index) => (
            <Card
              spinner={spinner}
              item={item}
              type={cardData?.name}
              index={index}
              setShowOverlay={setShowOverlay}
              showOverlay={showOverlay}
              setoverlayData={setoverlayData}
            />
          ))}
        </Carousel>
      )}
    </>
  );
};
