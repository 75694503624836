import React, { useState } from "react";
import { SliderComp } from "../Careousal/Careousal";
import { useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import Loading from "../../components/Loader/Loading";

import SeeMore from "../../assets/icons/See-More.png";
import "./productcarousel.css";
import { useNavigate } from "react-router";
import { routes } from "../../routes";
import Card from "../../components/Card/Card";
import { ArrowUpRight } from "lucide-react";

const ProductCarousel = ({
  icon,
  Boxicon,
  cardData,
  productTYpe,
  setoverlayData,
  setShowOverlay,
  showOverlay,
  loading,
  name,
  description,
  spinner,
  numSlide,
}) => {
  const isDarkMode = useSelector((state) => state.theme.darkMode);
  const navigate = useNavigate();

  return (
    <>
      <div className={isDarkMode ? "bg-product-dark" : "bg-product-light"}>
        <div className="mt-1">
          <div className="product-div">
            {Boxicon ? (
              <img src={Boxicon} className="fire-img" height={44} width={50} />
            ) : (
              <span
                dangerouslySetInnerHTML={{ __html: icon }}
                className="fire-img"
                height={44}
                width={50}
              />
            )}

            <span className={isDarkMode ? "product-text " : "product-text "}>
              {cardData?.name ? cardData?.name : name}
            </span>
          </div>

          <div className="discription-col">
            <span
              className={
                isDarkMode
                  ? "product-description cardtext-dark"
                  : "product-description cardtext-light"
              }
            >
              {cardData?.description ? cardData?.description : description}
            </span>
            {!productTYpe && (
              <div
                className="see-more-product"
                onClick={() =>
                  navigate(routes.UNBOXES, {
                    state: {
                      boxCategory: cardData?.name,
                    },
                  })
                }
              >
                <a className="see-more-text">See More</a>
                <ArrowUpRight style={{ color: "#e30613" }} />
              </div>
            )}
          </div>
        </div>
        <Container className="mt-3 " fluid>
          <div className="loader-icon">{loading && <Loading />}</div>

          {spinner === "spinner" ? (
            <Row>
              {cardData?.box?.map((item, index) => (
                <Col lg={3} md={4} sm={6} xs={12} xl={2} className="mb-2">
                  <Card
                    spinner={spinner}
                    item={item}
                    type={cardData?.name}
                    index={index}
                    setShowOverlay={setShowOverlay}
                    showOverlay={showOverlay}
                    setoverlayData={setoverlayData}
                  />
                </Col>
              ))}
            </Row>
          ) : (
            <>
              <SliderComp
                spinner={spinner}
                setShowOverlay={setShowOverlay}
                showOverlay={showOverlay}
                setoverlayData={setoverlayData}
                autoplay={false}
                autoplaySpeed={3000}
                slideNum={numSlide < 4 ? numSlide : 4}
                cardData={cardData}
              />
            </>
          )}
        </Container>
      </div>
    </>
  );
};

export default ProductCarousel;
