import React, { useState } from "react";
import "./imageupload.css";
import Toaster from "../Toast/Toast";
import { useDispatch, useSelector } from "react-redux";
import { updateUserAvatar } from "../../redux/slices/authSlice";
import { useEffect } from "react";
const ImageUploader = () => {
  const [previewImage, setPreviewImage] = useState();
  const [error, setError] = useState({ success: "", error: "" });
  const avatar = useSelector((state) => state?.auth?.user?.avatar);
  const dispatch = useDispatch();

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("avatar", file);
    const reader = new FileReader();

    reader.onloadend = () => {
      setPreviewImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
    try {
      const res = await dispatch(updateUserAvatar(formData));
      setError((prev) => ({
        ...prev,
        success: res?.data?.message || "Avatar Updated Successfully",
        error: res?.data?.error?.message || "",
      }));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setPreviewImage(null);
  }, [avatar]);

  return (
    <>
      <Toaster message={error} />
      <div className="imgupldmain">
        <img
          src={`${process.env.REACT_APP_CDN_URL}/images/${avatar}`}
          alt=""
        />
        <label for="images" class="drop-container">
          <input type="file" accept="image/*" onChange={handleImageChange} />
        </label>
        <div>
          {previewImage && (
            <img
              src={previewImage}
              alt="Preview"
              style={{ width: "200px", height: "200px" }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ImageUploader;
