import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./footer.css";
import { useSelector } from "react-redux";
import payments from "../../../assets/images/payments.png";
import { routes } from "../../../routes";
import {
  FooterLinks,
  GetFooterLogo,
} from "../../../api/modules/loothoot.class";
const Footer = (props) => {
  const isDarkMode = useSelector((state) => state.theme.darkMode);
  const [social, SetSocial] = useState();
  const [logoDark, setLogoDark] = useState();
  const [logoLight, setLogoLight] = useState();

  useEffect(() => {
    try {
      const api = async () => {
        const response = await FooterLinks();

        SetSocial(response?.data?.data);
        const footerlogo = await GetFooterLogo();
        setLogoDark(footerlogo?.data?.data?.[0]?.footer_logo);
        setLogoLight(footerlogo?.data?.data?.[1]?.footer_logo);
      };
      api();
    } catch (error) {
      console.log(error);
    }
  }, [logoDark, logoLight]);

  return (
    <>
      <div
        className={
          isDarkMode ? "footer-container-Dark" : "footer-container-Light"
        }
      >
        <div className="footer-part1">
          <div className="footer-social-div">
            <img
              src={
                isDarkMode
                  ? `${process.env.REACT_APP_BACKEND_URL}/public/${logoDark}`
                  : `${process.env.REACT_APP_BACKEND_URL}/public/${logoLight}`
              }
              alt=""
              className="logo-img-footer"
            />
            <p className="footer-social-text">
              Check out some of the hottest unboxings from our community! To
              appear on our page, tag us on{" "}
              <a
                href="https://www.instagram.com/loothootofficial/?igshid=MzRlODBiNWFlZA%3D%3D"
                target="_blank"
                className="insta-twitter-link" rel="noreferrer"
              >
                {" "}
                Instagram{" "}
              </a>
              or{" "}
              <a
                href="https://twitter.com/i/flow/login?redirect_after_login=%2Floothoot"
                className="insta-twitter-link"
                target="_blank" rel="noreferrer"
              >
                Twitter
              </a>{" "}
              when your item arrives.
            </p>
            <div className="socialdiv">
              {social?.map((item, index) => (
                <div
                  className=" social-icons-size"
                  style={{ background: item?.logo_color }}
                >
                  <a href={item?.url} target="_blank" rel="noreferrer">
                    <img
                      src={`${process.env.REACT_APP_BACKEND_URL}/public/${item?.footer_icon_logo}`}
                      className="icon-setting"
                      alt=" "
                    />
                  </a>
                </div>
              ))}
              {/* {social?.[1]?.name === "Instagram" ? (
                <div className="social-icons-insta social-icons-size">
                  <a href={social?.[1]?.url} target="_blank">
                    <BsInstagram className="icon-setting" />
                  </a>
                </div>
              ) : (
                <></>
              )}

              {social?.[2]?.name === "Ticktok" ? (
                <div
                  className={
                    isDarkMode
                      ? "social-icons-tiktok-dark social-icons-size "
                      : "social-icons-tiktok-light social-icons-size"
                  }
                >
                  <a href={social?.[2]?.url} target="_blank">
                    <SiTiktok
                      className={
                        isDarkMode
                          ? "tiktok-icon-dark-mode icon-setting"
                          : "icon-setting"
                      }
                    />
                  </a>
                </div>
              ) : (
                <></>
              )}

              {social?.[3]?.name === "Kick" ? (
                <div className="social-icons-kick social-icons-size">
                  <a href={social?.[3]?.url} target="_blank">
                    <TbBrandKickstarter className="icon-setting" />
                  </a>
                </div>
              ) : (
                <></>
              )} */}
            </div>
          </div>
          <div className="footer-payments">
            <div className="footer-trustpilot">
              <div
                class="trustpilot-widget"
                data-locale="en-US"
                data-template-id="5419b6a8b0d04a076446a9ad"
                data-businessunit-id="630e10b9d31aff5bf1cec9b4"
                data-style-height="50px"
                data-style-width="100%"
                data-theme="light"
                data-min-review-count="0"
                data-without-reviews-preferred-string-id="1"
                data-style-alignment="center"
              >
                <a
                  href="https://www.trustpilot.com/review/www.loothoot.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Trustpilot
                </a>
              </div>
            </div>

            <div className="footer-trustpilot">
              <p className="trustpilot-text">Payments</p>
              <div className="footer-payment-icons">
                <img src={payments} alt="" height={"100px"} />
              </div>
            </div>
          </div>
        </div>
        <div className="footer-part2">
          <Link
            to={routes.CONTACTUS}
            className={
              isDarkMode
                ? "footer-part2-link-Dark footer-part2-link"
                : " footer-part2-link footer-part2-link-light"
            }
          >
            Contact
          </Link>
          |
          <Link
            to={routes.FAQ}
            className={
              isDarkMode
                ? "footer-part2-link-Dark footer-part2-link"
                : " footer-part2-link footer-part2-link-light"
            }
          >
            FAQ
          </Link>
          |
          <Link
            to={routes.TERMS}
            className={
              isDarkMode
                ? "footer-part2-link-Dark footer-part2-link"
                : " footer-part2-link footer-part2-link-light"
            }
          >
            Terms of Service
          </Link>
          |
          <Link
            to={routes.PRIVACYPAYMENTS}
            className={
              isDarkMode
                ? "footer-part2-link-Dark footer-part2-link"
                : " footer-part2-link footer-part2-link-light"
            }
          >
            Privacy Payments
          </Link>
          |
          <Link
            to={routes.PROVABLYFAIR}
            className={
              isDarkMode
                ? "footer-part2-link-Dark footer-part2-link"
                : " footer-part2-link footer-part2-link-light"
            }
          >
            Provably Fair
          </Link>
          |
          <Link
            to={routes.COOKIE}
            className={
              isDarkMode
                ? "footer-part2-link-Dark footer-part2-link"
                : " footer-part2-link footer-part2-link-light"
            }
          >
            Cookie Policy
          </Link>
          |
          <Link
            to={routes.AMLPOLICY}
            className={
              isDarkMode
                ? "footer-part2-link-Dark footer-part2-link"
                : " footer-part2-link footer-part2-link-light"
            }
          >
            AML Policy
          </Link>
        </div>
        <div className="footer-part3">
          <p className="footer-part3-text">
            One LootHoot Limited Company Number: 238559 Address: 306 Victoria
            House, Victoria, Mahe, Seychelles
          </p>
        </div>
        <div className="last-part-footer"> </div>
      </div>
    </>
  );
};

export default Footer;
