import React from "react";
import comingsoon from "../../assets/images/comingSoon.png";

const Affliates = () => {
  return (
    <div className="text-center p-4">
      <h1>Affliates Coming Soon...</h1>
      <img src={comingsoon} alt="" width={"200px"} />
    </div>
  );
};

export default Affliates;
