import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "../../routes";
import { useLocation } from "react-router-dom";

import "./card.css";
const CardBox = ({
  item,
  index,
  setShowOverlay,
  setoverlayData,
  type,
  spinner,
}) => {
  const location = useLocation();
  const { slugId } = useParams();

  const handleOverlayClick = () => {
    setShowOverlay((prevState) => !prevState);
    setoverlayData(item);
  };
  const encryptData = (data) => {
    const base64EncodedData = window?.btoa(data?.toString()); // Convert the integer to a string and then Base64 encode it
    return base64EncodedData;
  };
  const handleClick = () => {
    const boxNameEncrypt = encryptData(item?.slug);

    if (
      location?.pathname === routes?.UNBOXES ||
      location?.pathname === routes?.HOME ||
      location?.pathname === routes.DEALS
    ) {
      navigate(`${routes.BOXDETAIL}/${boxNameEncrypt}`);
    } else if (location?.pathname === `${routes.BOXDETAIL}/${slugId}`) {
      handleOverlayClick();
    }
  };

  // item price on hover only
  const [hoveredCards, setHoveredCards] = useState([]);

  const handleMouseIN = (cardIndex) => {
    setHoveredCards((prevHoveredCards) => [...prevHoveredCards, cardIndex]);
  };

  const handleMouseOUT = (cardIndex) => {
    setHoveredCards((prevHoveredCards) =>
      prevHoveredCards.filter((index) => index !== cardIndex),
    );
  };

  //
  const isDarkMode = useSelector((state) => state?.theme?.darkMode);
  const navigate = useNavigate();

  return (
    item && (
      <Card
        style={{
          minHeight: spinner === "spinner" ? "310px" : "",
        }}
        bsPrefix={
          isDarkMode
            ? "card custom-card custom-card-dark"
            : "card custom-card custom-card-light"
        }
        onMouseEnter={() => handleMouseIN(index)}
        onMouseLeave={() => handleMouseOUT(index)}
        onClick={handleClick}
      >
        <div className="position-absolute top-0 start-0 badges">
          {type === "Featured" && (
            <button className="featured-button">{type}</button>
          )}
          {type === "Hot" && <button className="newbutton">{type}</button>}
          {type === "Updated" && (
            <button className="featured-button">{type}</button>
          )}
        </div>
        <div className="card-img-div">
          <Card.Img
            className="cardimg"
            variant="top"
            src={`${process.env.REACT_APP_CDN_URL}/images/${
              item?.box_image ? item?.box_image?.image : item?.image
            }`}
          />
        </div>
        <Card.Body>
          <Card.Title
            className={
              isDarkMode
                ? "cardtitle cardtext-dark"
                : "cardtitle cardtext-light"
            }
            style={{ marginBottom: "0px" }}
          >
            {item?.name}
          </Card.Title>

          <div
            class="reveal"
            style={{
              backgroundColor: item?.box_background_image
                ? item?.box_background_image
                : "black",
            }}
          >
            <p className="item-price">{`$ ${
              item?.box_price ? item?.box_price : item?.price
            }`}</p>
          </div>
        </Card.Body>
      </Card>
    )
  );
};

export default CardBox;
