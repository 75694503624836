import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import "./cart.css";
import MyaccountList from "../../components/myaccount-lists/MyaccountList";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/slices/authSlice";
import { getCart } from "../../api/modules/loothoot.class";
import Loading from "../../components/Loader/Loading";
import Toaster from "../../components/Toast/Toast";
import ConfirmModal from "../../components/SellItemsConfirm/Confirm";
import { togglePayment, setActiveTab } from "../../redux/slices/accountSlice";
import MultiSelect from "./MultiSelect";

function CartItem({ item, setData }) {
  const isDarkMode = useSelector((state) => state?.theme?.darkMode);
  const [error, setError] = useState({ success: "", error: "" });
  const [modalShow, setModalShow] = useState(false);
  const [shipShow, setShipShow] = useState(false);
  const dispatch = useDispatch();

  const openWithdrawModal = () => {
    dispatch(togglePayment({ payment: true }));
    dispatch(setActiveTab("withdraw"));
  };
  return (
    <>
      <Toaster message={error} />

      <div
        className={
          isDarkMode ? "cart-info-dark cart-info" : "cart-info-light cart-info"
        }
      >
        <span>{item?.name}</span>
        <img
          src={`${process.env.REACT_APP_CDN_URL}/images/${item?.image}`}
          width="150"
          className="imgbox"
          alt=" "
        />

        <span style={{ color: "#8e8e99", fontSize: "12px" }}>
          Total Unboxed Value
        </span>
        <span style={{ fontWeight: "700", fontSize: "15px" }}>
          $ {item?.price}
        </span>

        <div className="d-flex justify-content-center flex-wrap gap-2">
          <button
            className="myaccount-menu-btn mx-2"
            onClick={() => setShipShow(true)}
          >
            <span>Ship</span>
          </button>
          <button
            className="myaccount-menu-btn"
            onClick={() => setModalShow(true)}
          >
            <span> Sell</span>
          </button>
          <button className="myaccount-menu-btn" onClick={openWithdrawModal}>
            <span> Withdraw</span>
          </button>
        </div>
      </div>
      <ConfirmModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        setModalShow={setModalShow}
        setError={setError}
        item={item}
        setData={setData}
      />
      <ConfirmModal
        show={shipShow}
        onHide={() => setShipShow(false)}
        setModalShow={setShipShow}
        setError={setError}
        item={item}
        setData={setData}
        type="ship"
      />
    </>
  );
}

const MyAccount = () => {
  const [loading, setLoading] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const user = useSelector((state) => state?.auth?.user);
  const [data, setData] = useState();
  const [cart, setCart] = useState();
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout());
  };


  useEffect(() => {
    const handleCart = async () => {
      try {
        const response = await getCart(user?.id);
        setData(response?.data?.data);
  
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };

    handleCart();
  }, [cart, user?.id]);

  return (
    <>
      {/* <Dashboard /> */}
      <MyaccountList />

      <div className="cart-main">
        <h1 className="heading1"> CART</h1>
        {data?.length > 1 && (
          <button
            className="multiselectButton"
            onClick={() => setModalShow(true)}
          >
            <span> MultiSelect</span>
          </button>
        )}
      </div>
      <div className="loader-icon">{loading && <Loading />}</div>
      {data?.length === 0 && <h4 className=" empty-cart">Cart is Empty</h4>}
      <div className="container px-3 d-flex justify-content-start flex-wrap">
        {data?.map((item, index) => (
          <CartItem item={item} index={index} setData={setData} />
        ))}
      </div>
      <div className="logout-button-section">
        <button className="logout-button" onClick={handleLogout}>
          <span>log out</span>
        </button>
      </div>

      <MultiSelect
        show={modalShow}
        onHide={() => setModalShow(false)}
        data={data}
        setCart={setCart}
      />
    </>
  );
};

export default MyAccount;
