import { createSlice } from "@reduxjs/toolkit";

const initialState = { login: false, register: false };

export const onBoardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    toggleOnboard: (state, action) => {
      state.login = action?.payload?.login || false;
      state.register = action?.payload?.register || false;
    },
  },
});

export const { toggleOnboard } = onBoardingSlice.actions;
export default onBoardingSlice.reducer;
