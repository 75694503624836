import React, { useState } from "react";
import battle1 from "../../assets/images/Battles/battle1.png";
import battle2 from "../../assets/images/Battles/battle2.png";
import Icon_Battle from "../../assets/images/Battles/Icon_ Battle.png";
import Icon_Battle_dark from "../../assets/images/Battles/Icon_ Battle-dark.png";
import TimeLine from "../Timeline/TimeLine";
import { useSelector, useDispatch } from "react-redux";
import "./battle.css";
import { routes } from "../../routes";
import { useNavigate } from "react-router";
import { toggleOnboard } from "../../redux/slices/onBoardingSlice";
import {
  battlePlayTrigger,
  joinBattle,
} from "../../api/modules/loothoot.class";
import TopUpModal from "../TopupModal/TopUpModal";
import { BsFillEyeFill } from "react-icons/bs";

const Battle = ({ item, index }) => {
  const isDarkMode = useSelector((state) => state?.theme?.darkMode);
  const isLoggedIn = useSelector((state) => state?.auth?.isLoggedIn);
  const user = useSelector((state) => state?.auth?.user);
  const balance = useSelector((state) => state?.account?.balance);
  const dispatch = useDispatch();
  const [topUp, setTopup] = useState(false);
  const navigate = useNavigate();

  const encryptData = (data) => {
    const base64EncodedData = window.btoa(data.toString());
    return base64EncodedData;
  };

  const handleJoinBattle = (status, player) => {
    const encryptedData = encryptData(item?.id);

    if (isLoggedIn && player[0]?.id === user?.id) {
      navigate(`${routes.BATTLEDETAIL}/${encryptedData}`);
    } else if (isLoggedIn && status === "active") {
      if (balance >= item?.amount) {
        joinBattle({ battle_id: item?.id })
          .then((response) => {
            if (
              response?.data?.success &&
              response?.data?.message === "joined"
            ) {
              battlePlayTrigger({ battle_id: item?.id }).then((response) => {});
              navigate(`${routes.BATTLEDETAIL}/${encryptedData}`);
            }
          })
          .catch((e) => {
            console.error(e);
          });
      } else {
        setTopup(true);
      }
    } else dispatch(toggleOnboard({ login: true }));
  };

  return (
    <>
      <div
        className={
          isDarkMode
            ? " battles-main-dark battles-main"
            : "battles-main-light battles-main"
        }
      >
        <div className="battles-head">
          <div className="w-100 d-flex justify-content-between">
            <button className="battles-head-buton">{item?.status}</button>
            {item?.private_battle ? <BsFillEyeFill /> : null}
          </div>

          <div className="battles-head-icons mt-2">
            <div>
              <div>
                <img
                  src={
                    item?.users?.length > 0
                      ? item?.users[0]?.userdetails?.avatar
                        ? `${process.env.REACT_APP_CDN_URL}/images/${item?.users[0]?.userdetails?.avatar}`
                        : battle1
                      : null
                  }
                  alt=""
                  className="battles-head-img"
                />
              </div>
            </div>
            <div className="icon_battle-div">
              <img src={isDarkMode ? Icon_Battle_dark : Icon_Battle} alt="" />
            </div>
            <div>
              {item?.status === "playing" || item?.status === "complete" ? (
                <img
                  src={
                    item?.users?.length > 1
                      ? item?.users[1]?.userdetails?.avatar
                        ? `${process.env.REACT_APP_CDN_URL}/images/${item?.users[1]?.userdetails?.avatar}`
                        : battle2
                      : null
                  }
                  alt=""
                  className="battles-head-img"
                />
              ) : (
                <div className="dummy-image"></div>
              )}
            </div>
          </div>
        </div>
        <hr className="battles-hr" />
        <TimeLine item={item} index={index} />
        <div className="battles-footer"></div>

        <div className={item?.crazy_mod ? "eclipse_crazy" : "eclipse"}>
          <p className="watch-battle-text">
            {/* {item?.status != "complete" || item?.creator != user?.id ? ( */}
            <div
              onClick={() => {
                if (
                  item?.users?.length <= 1 ||
                  item?.users?.some((record) => record.id === user?.id)
                ) {
                  handleJoinBattle(
                    item?.status,
                    item?.users?.filter((x) => x?.id === user?.id),
                  );
                }
              }}
            >
              {(item?.users?.length > 1 &&
                item?.users?.some((record) => record.id === user?.id)) ||
              item?.creator === user?.id ? (
                "Go to battle"
              ) : item?.users?.length > 1 &&
                !item?.users?.some((record) => record.id === user?.id) ? (
                <>
                  Total Unboxed{" "}
                  <span
                    className={
                      isDarkMode
                        ? "battles-footer-price battles-footer-price-dark"
                        : "battles-footer-price battles-footer-light"
                    }
                  >
                    ${item?.player1_amount + item?.player2_amount}
                  </span>
                </>
              ) : (
                <>
                  Join Battle{" "}
                  <span
                    className={
                      isDarkMode
                        ? "battles-footer-price battles-footer-price-dark"
                        : "battles-footer-price battles-footer-light"
                    }
                  >
                    ${item?.amount}
                  </span>
                </>
              )}
            </div>
            {/* // ) : (
            //   <div onClick={handleMyBattle}>Go to battle</div>
            // )} */}
          </p>
        </div>
      </div>
      <TopUpModal setTopUpModalShow={setTopup} show={topUp} />
    </>
  );
};

export default Battle;
