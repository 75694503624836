import { createSlice } from "@reduxjs/toolkit";

const initialState = { balance: 0, payment: false, activeTab: "deposit" };

export const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    setAccount: (state, action) => {
      state.balance = action?.payload?.balance;
    },
    togglePayment: (state, action) => {
      state.payment = action?.payload?.payment || false;
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
  },
});

export const { setAccount, togglePayment, setActiveTab } = accountSlice.actions;
export default accountSlice.reducer;
