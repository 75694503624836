import Slider from "react-slick";
import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./careousalFresh.css";
import { useResponsive } from "./useResponsive";
import { useRef } from "react";
import { useSelector } from "react-redux";
import freshdelivery from "../../assets/images/freshdeliveries/freshdelivery.png";
import freshdelivery2 from "../../assets/images/freshdeliveries/freshdelivery2.png";
import freshdelivery3 from "../../assets/images/freshdeliveries/freshdelivery3.png";
import deliveryarrow from "../../assets/images/freshdeliveries/deliveryarrow.png";
import deliveryarrowdark from "../../assets/images/freshdeliveries/deliveryaroowdark.png";
export const SliderComp = ({ slideNum, autoplay, autoplaySpeed }) => {
  const { screenType } = useResponsive();
  const sliderRef = useRef();
  const settings = {
    arrows: false,
    dots: false,
    swipeToSlide: true,
    infinite: true,
    autoplay: autoplay,
    autoplaySpeed: autoplaySpeed,
    slidesToScroll: 1,
    slidesToShow:
      screenType === "MOBILE"
        ? 1
        : screenType === "TABLET"
        ? 2
        : screenType === "LAPTOP"
        ? 3
        : slideNum,
  };

  const isDarkMode = useSelector((state) => state.theme.darkMode);

  const cardimg = [
    {
      dataimg: freshdelivery,
    },
    {
      dataimg: freshdelivery2,
    },
    {
      dataimg: freshdelivery3,
    },
    {
      dataimg: freshdelivery3,
    },
  ];
  return (
    <>
      <div className="sliderMain">
        <Slider {...settings} ref={sliderRef}>
          {cardimg?.map((item, index) => (
            <>
              <div className="div-main">
                <div className="position-relative">
                  <img
                    src={item.dataimg}
                    className="card-img-top"
                    alt=" "
                  />

                  <button
                    className={
                      isDarkMode
                        ? "position-absolute bottom-0 end-0 m-3 delivery-icon delivery-icon-dark "
                        : "position-absolute bottom-0 end-0 m-3 delivery-icon delivery-icon-light "
                    }
                  >
                    <a
                      href="https://www.instagram.com/loothootofficial/?igshid=MzRlODBiNWFlZA%3D%3D"
                      target="_blank" rel="noreferrer"
                    >
                      <img
                        src={isDarkMode ? deliveryarrowdark : deliveryarrow}
                        alt=""
                      />
                    </a>
                  </button>
                </div>
              </div>
            </>
          ))}
        </Slider>
      </div>
      {/* </div> */}
      {/* <div className="sliderButtonsMain">
        <div
          onClick={() => sliderRef.current.slickPrev()}
          className={
            isDarkMode
              ? "slider-prev-button slider-prev-button-dark"
              : "slider-prev-button slider-prev-button-light"
          }
        >
          <img src={isDarkMode ? arrowbackdark : arrowback} alt="" />
        </div>

        <div
          onClick={() => sliderRef.current.slickNext()}
          className="slider-next-button"
        >
          <img src={arrownext} alt="" />
        </div>
      </div> */}
    </>
  );
};
