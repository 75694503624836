import React from "react";
import "./verified.css";
import Verify from "../../assets/images/verified.png";

const verified = () => {
  return (
    <div className="verified-main">
      <div>
        <img
          src={Verify}
          alt="Verified"
          className="verified-icon"
          width={100}
          height={100}
        />
        <span className="verified-text">Your Email is Now Verified</span>
      </div>
      <div>
        <h4>You're all set! Login Now to complete your profile.</h4>
      </div>
    </div>
  );
};

export default verified;
