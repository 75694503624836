import { createSlice } from "@reduxjs/toolkit";

const mode = JSON.parse(localStorage.getItem("isDarkMode"));

const initialState = mode ? { darkMode: true } : { darkMode: false };

export const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    toggleTheme: (state) => {
      state.darkMode = !state.darkMode;
      localStorage.setItem("isDarkMode", JSON.stringify(state.darkMode));
    },
  },
});

export const { toggleTheme } = themeSlice.actions;
export default themeSlice.reducer;
