import React from "react";
import HomePageCarousel from "../homepagecarousel/HomePageCarousel";
import BoxTabs from "./BoxTabs/BoxTabs";

const UnBoxing = () => {
  return (
    <>
      <HomePageCarousel />
      {/* <FeaturedBox /> */}
      <BoxTabs />
    </>
  );
};

export default UnBoxing;
