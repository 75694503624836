import React, { useEffect, useState } from "react";
import Battle from "../Battle/Battle";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./allbattles.css";
import loadmore from "../../assets/icons/load-more.png";
import battleicon from "../../assets/images/Battles/battleicon.png";
import {
  getAllBattles,
  getAllBattlesByUser,
} from "../../api/modules/loothoot.class";
import { routes } from "../../routes";
import { toggleOnboard } from "../../redux/slices/onBoardingSlice";
import TopUpModal from "../TopupModal/TopUpModal";
import FullAppLoader from "../Loader/FullAppLoader";
import { toggleLoader } from "../../redux/slices/loaderSlice";
const AllBattle = () => {
  const isLoading = useSelector((state) => state?.loader?.isLoading);
  const [topUp, setTopup] = useState(false);
  const user = useSelector((state) => state?.auth?.user);

  const [allbattles, SetAllBattles] = useState();
  const [result, setResult] = useState(20);
  const [count, setCount] = useState();
  const isDarkMode = useSelector((state) => state?.theme?.darkMode);
  const isLoggedIn = useSelector((state) => state?.auth?.isLoggedIn);
  const balance = useSelector((state) => state?.account?.balance);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const getAll = () => {
      let response;
      try {
        response = isLoggedIn
          ? getAllBattlesByUser(result)
          : getAllBattles(result);
        response.then((response) => {
          setCount(response?.data?.data?.count);
  
          if (response?.data?.data?.data?.length > 0) {
            const filteredBattles = response?.data?.data?.data?.map((battle) => {
              if (battle?.private_battle === 1 && battle?.creator !== user?.id) {
                return null;
              } else return battle;
            });
  
            SetAllBattles(filteredBattles);
          } else SetAllBattles([]);
        });
  
        dispatch(toggleLoader({ isLoading: false }));
      } catch (err) {
        console.log(err);
      }
    };
    dispatch(toggleLoader({ isLoading: true }));
    const intervalCall = setInterval(() => {
      getAll();
    }, 5000);

    return () => {
      clearInterval(intervalCall);
    };
  }, [dispatch, result, isLoggedIn, user?.id]);

  const handleCreate = () => {
    if (isLoggedIn) {
      if (balance > 0) {
        navigate(routes.CREATEBATTLE);
      } else {
        setTopup(true);
      }
    } else dispatch(toggleOnboard({ login: true }));
  };

  return isLoading ? (
    <FullAppLoader />
  ) : (
    <>
      <div className="allBattleMain ">
        <div className="allbattles-main-div">
          <img src={battleicon} alt="" />
          <span>Battles</span>
          {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
        </div>
        <button className="create-battle-btn" onClick={handleCreate}>
          <span>Create Battles </span>
        </button>
      </div>

      <div className="container mt-5">
        <div className="row p-3">
          {allbattles?.length > 0 ? (
            allbattles?.map(
              (item, index) =>
                item != null && (
                  <div className="col-md-3 col-sm-6 col-xs-12">
                    <Battle item={item} index={index} />
                  </div>
                ),
            )
          ) : (
            <div className="d-flex justify-content-center">
              No Battles Available
            </div>
          )}
        </div>
      </div>
      {count > 20 ? (
        <div
          className={
            isDarkMode
              ? " load-more-div-dark load-more-div"
              : "load-more-div-light load-more-div"
          }
        >
          <button
            className="mb-5"
            onClick={() => setResult((prev) => prev + 20)}
          >
            Load More <img src={loadmore} alt="" />
          </button>
        </div>
      ) : (
        <></>
      )}

      <TopUpModal setTopUpModalShow={setTopup} show={topUp} />
    </>
  );
};

export default AllBattle;
