export const routes = {
  HOME: "/",
  UNBOXES: "/unboxing",
  BOXDETAIL: "/unboxing/box/spin",
  BATTLES: "/battles",
  BATTLEDETAIL: "/battle/play",
  CREATEBATTLE: "/battles/create/battle",
  ACCOUNT: "/myaccount",
  CART: "/myaccount/cart",
  AVATAR: "/myaccount/avatar",
  FAIRNESS: "/myaccount/fairness",
  DOCUMENTS: "/myaccount/documents",
  GAMEHISTORY: "/myaccount/game/history",
  PROMO: "/myaccount/promo",
  QUICKUPGRADE: "/myaccount/quick-upgrade",
  STATS: "/myaccount/stats",
  TOPUPS: "/myaccount/top-ups",
  TRANSACTIONS: "/myaccount/transactions",
  CONTACTUS: "/contact",
  BLOG: "/blog",
  FAQ: "/faq",
  TERMS: "/service/terms",
  PRIVACYPAYMENTS: "/privacy/payments",
  PROVABLYFAIR: "/provably/fair",
  COOKIE: "/cookie/policy",
  AMLPOLICY: "/aml/policy",
  DEALS: "/deals",
  AFFLIATES: "/affliates",
  FREEDROP: "/free/drop",
  VERIFIED: "/verified",
  RESET: "/reset",
};
