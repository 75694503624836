import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./freshdelivery.css";
import SeeMore from "../../assets/icons/See-More.png";
import { useSelector } from "react-redux";
import { SliderComp } from "../CareousalFreshDelivery/CareousalFresh";
import shippdelivery from "../../assets/images/freshdeliveries/shippdelivery.png";
import { ArrowUpRight } from "lucide-react";

const FreshDelivery = ({ title, image, description, price }) => {
  const isDarkMode = useSelector((state) => state.theme.darkMode);

  return (
    <>
      <div className="mt-5 container-fluid mainfreshdiv">
        <Row>
          <Col>
            <div className="fresh-div">
              <img src={shippdelivery} className="fresh-img" />
              <span
                className={
                  isDarkMode
                    ? "fresh-text cardtext-dark"
                    : "fresh-text cardtext-light"
                }
              >
                Fresh Deliveries
              </span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="col-xs-4 fresh-description-div">
            <span
              className={
                isDarkMode
                  ? "fresh-description cardtext-dark"
                  : "fresh-description cardtext-light"
              }
            >
              Check out some of the hottest unboxings from our community! To
              appear on our page, tag us on{" "}
              <a
                href="https://www.instagram.com/loothootofficial/?igshid=MzRlODBiNWFlZA%3D%3D"
                className="insta-twitter-link"
                target="_blank" rel="noreferrer"
              >
                Instagram
              </a>{" "}
              or{" "}
              <a
                href="https://twitter.com/loothoot?s=11&t=CEpn3_sVRWWL0siFuCdKqg"
                className="insta-twitter-link"
                target="_blank" rel="noreferrer"
              >
                Twitter
              </a>{" "}
              when your item arrives.
            </span>
            <div className="see-more-div">
              <a
                className="see-more"
                href="https://www.instagram.com/loothootofficial/?igshid=MzRlODBiNWFlZA%3D%3D"
                target="_blank" rel="noreferrer"
              >
                See on Social Media
              </a>
              <ArrowUpRight style={{ color: "#e30613" }} />
            </div>
          </Col>
        </Row>
      </div>
      <Container className="mt-3 mb-5" fluid>
        <SliderComp autoplay={false} autoplaySpeed={3000} slideNum={3} />
      </Container>
    </>
  );
};

export default FreshDelivery;
