import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TextInput from "../../../TextInput/TextInput";
import QrCode from "react-qr-code";
import {
  ExchangeRate,
  getWalletAddress,
} from "../../../../api/modules/loothoot.class";
import Loading from "../../../Loader/Loading";

const DepositAddress = ({ item }) => {
  const isDarkMode = useSelector((state) => state.theme.darkMode);
  const [address, setAddress] = useState(null);
  const [coinAmount, setCoinAmount] = useState(1);
  const [convertedAmount, setConvertedAmount] = useState(0);
  const [rate, setRate] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let coinAddress = null;
    const getAddress = async () => {
      setLoading(true);
      try {
        await getWalletAddress({
          coin:
            item?.coin === "bnb_bsc"
              ? "eth"
              : item?.coin === "usdt_erc20"
              ? "eth"
              : item?.coin,
        })
          .then((response) => {
            coinAddress = response?.data?.data;
          })
          .catch((error) => {
            console.log("error", error);
          });
        setAddress(coinAddress);
        const res = await ExchangeRate({
          coin: item?.coin === "bnb_bsc" ? "bnb" : item?.coin,
        });
        setRate(res?.data?.data?.data?.rate);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    getAddress();
  }, [item?.coin]);

  useEffect(() => {
    setConvertedAmount(coinAmount * rate);
  }, [coinAmount, rate]);

  const handleCoinAmountChange = (e) => {
    const newCoinAmount = e.target.value;
    setCoinAmount(isNaN(newCoinAmount) ? 0 : newCoinAmount);
  };

  return loading ? (
    <div className="loader-payment-gateway">
      <Loading />
    </div>
  ) : (
    <>
      <div>
        <div className="p-1">
          <div
            className={
              isDarkMode ? "deposit-heading-dark" : "deposit-heading-light"
            }
          >
            <h2>
              Deposit with{" "}
              {item?.coin === "usdt_erc20"
                ? "USDT"
                : item?.coin === "bnb_bsc"
                ? "BNB"
                : item?.coin}{" "}
            </h2>
          </div>
          {/* {address ? ( */}
          <>
            <div className="walletAddress">
              <div
                className={
                  isDarkMode
                    ? "amount-div amount-div-dark"
                    : "amount-div-light amount-div"
                }
              >
                <h6>
                  YOUR{" "}
                  {item?.coin === "usdt_erc20"
                    ? "USDT"
                    : item?.coin === "bnb_bsc"
                    ? "BNB"
                    : item?.coin.toUpperCase()}{" "}
                  DEPOSIT ADDRESS
                </h6>
              </div>
              <TextInput
                isDarkMode={isDarkMode}
                type="text"
                name="coin"
                disabled={true}
                // placeholder="3J9LhJQn7gg7RphXmK3mCS29GGxQaQLssG"
                // onChange={handleChange}
                value={address ? address : ""}
              />
            </div>
            <div className="d-flex justify-content-center align-items-center p-2">
              {!address ? (
                <></>
              ) : (
                <QrCode value={address ? address : ""} size={200} />
              )}
            </div>
          </>
          {/* ) : (
            <></>
          )} */}
          <div>
            <h6
              className={
                isDarkMode ? "deposit-heading-dark" : "deposit-heading-light"
              }
            >
              {item?.coin === "usdt_erc20"
                ? "USDT"
                : item?.coin === "bnb_bsc"
                ? "BNB"
                : item?.coin}{" "}
              Calculator
            </h6>
            <div className=" btc-conversion">
              {/* <img
                  src="https://static.ancientgaming.io/icons/bitcoin.svg"
                  alt=""
                  className="currency-img"
                /> */}
              <input
                type="text"
                placeholder={item?.coin?.toUpperCase()}
                value={coinAmount}
                onChange={handleCoinAmountChange}
                className="btc-input"
              />

              {/* <img
                  src="https://www.hypedrop.com/en/assets/icons/payment-icons/dollar.svg"
                  alt=""
                  className="currency-img"
                /> */}
              <input
                type="text"
                placeholder={"USDT"}
                value={
                  item?.coin === "usdt_erc20"
                    ? coinAmount
                    : convertedAmount === 0
                    ? item?.coin
                    : convertedAmount?.toFixed(2)
                }
                disabled
                className="btc-input"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DepositAddress;
