import React from "react";

import { Table } from "react-bootstrap";
import "./promocode.css";
import MyaccountList from "../../components/myaccount-lists/MyaccountList";
import { FaCog } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/slices/authSlice";
const MyAccount = () => {
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout());
  };
  return (
    <>
      {/* <Dashboard /> */}
      <MyaccountList />
      <div className="topup-table">
        <Table striped hover>
          <thead>
            <tr className="tablerow">
              <th className="tablehead">#</th>
              <th className="tablehead">ID</th>

              <th className="tablehead">CREATED</th>

              <th className="tablehead">UPDATED</th>
              <th className="tablehead">PROVIDER</th>
              <th className="tablehead">TYPE</th>
              <th className="tablehead">STATUS</th>
              <th className="tablehead">AMOUNT</th>
              <th className="tablehead">
                <FaCog />
              </th>
            </tr>
          </thead>
          <tbody>
            {/* Here you can map over your data and create a row for each item */}
            <tr style={{ textAlign: "center" }}></tr>
            {/* More rows */}
          </tbody>
        </Table>
      </div>
      <div className="logout-button-section mt-5">
        <button className="logout-button" onClick={handleLogout}>
          <span>log out</span>
        </button>
      </div>
    </>
  );
};

export default MyAccount;
