import React from "react";
import MyaccountList from "../../components/myaccount-lists/MyaccountList";

import ImageUploader from "../../components/uploader/ImageUploader";
const AvatarComp = () => {
  return (
    <>
      <MyaccountList />
      <ImageUploader />
    </>
  );
};

export default AvatarComp;
