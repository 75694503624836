import React, { useState } from "react";
// import * as Yup from "yup";
import { useSelector } from "react-redux";
import "./transactions.css";
import MyaccountList from "../../components/myaccount-lists/MyaccountList";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/slices/authSlice";
// import { components } from "react-select";
import {
  GetTransaction,
} from "../../api/modules/loothoot.class";
import { useEffect } from "react";
import Loading from "../../components/Loader/Loading";
const MyAccount = () => {
  const [transaction, setTransaction] = useState();
  const [loading, setLoading] = useState(true);
  // const OptionCheckbox = ({ children, ...props }) => (
  //   <components.Option {...props}>
  //     <input type="checkbox" checked={props.isSelected} onChange={() => null} />
  //     {children}
  //   </components.Option>
  // );
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout());
  };
  // const userid = useSelector((state) => state?.auth?.user?.id);
  // const typeOptions = [
  //   { value: "option1", label: " Achievement Claim" },
  //   { value: "option2", label: "Additional Rewards Daily Claimed" },
  //   { value: "option3", label: "Additional Rewards Daily" },
  //   { value: "option4", label: "Additional Rewards Weekly" },
  //   { value: "option5", label: "Additional Rewards Monthly Claimed " },
  //   { value: "option6", label: "Additional Rewards Monthly" },
  //   { value: "option7", label: "Affiliate Loan" },
  // ];

  // const usewalletOptions = [
  //   { value: "option1", label: " USD Main" },
  //   { value: "option2", label: "USD Affiliate Earnings" },
  //   { value: "option3", label: "USD Gemstone" },
  // ];
  // const initialValues = {
  //   min_date: "",
  //   max_date: "",
  // };

  useEffect(() => {
    try {
      const api = () => {
        const res = GetTransaction();
        res
          .then((response) => {
            setTransaction(response?.data?.data);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
          });
        console.log(res);
      };
      api();
    } catch (error) {
      console.log(error);
    }
  }, []);
  // const FilterSchema = Yup.object().shape({
  //   min_date: Yup.date()
  //     .required("Date is required")
  //     .min(new Date(), "Date must be in the future"),
  //   max_date: Yup.date()
  //     .required("Date is required")
  //     .min(new Date(), "Date must be in the future"),
  // });
  // const handleSubmit = (values) => {};

  const isDarkMode = useSelector((state) => state.theme.darkMode);

  return (
    <>
      {/* <Dashboard /> */}
      <MyaccountList />

      {/* <Formik
        initialValues={initialValues}
        validationSchema={FilterSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form className="personal-info-form">
            <div className="Toggles-input-feilds">
              <div className="loginElement">
                <label
                  className={
                    isDarkMode ? "label label-dark" : " label label-light"
                  }
                >
                  TYPES
                </label>
                <CustomSelect
                  options={typeOptions}
                  isMulti={true}
                  components={{ Option: OptionCheckbox }}
                />
              </div>
              <div className="loginElement">
                <label
                  className={
                    isDarkMode ? "label label-dark" : " label label-light"
                  }
                >
                  USER WALLET
                </label>
                <CustomSelect options={usewalletOptions} />
              </div>

              <div className="loginElement">
                <TextInput
                  isDarkMode={isDarkMode}
                  type="date"
                  label="MINDATE"
                  name="min_date"
                  onChange={handleChange}
                  value={values.password}
                />
              </div>
              <div className="loginElement">
                <TextInput
                  isDarkMode={isDarkMode}
                  type="date"
                  label="MAXDATE"
                  name="max_date"
                  onChange={handleChange}
                  value={values.password}
                />
              </div>
            </div>
            <div className="topup-buttons mt-5">
              <button
                className="filter-button"
                onClick={handleSubmit}
                type="submit"
              >
                <span>Filter</span>
              </button>
            </div>
          </Form>
        )}
      </Formik> */}
      <div className="loader-icon">{loading && <Loading />}</div>
      <div className="topup-table">
        <table className={isDarkMode ? "table table-dark" : "table"}>
          <thead>
            <tr className="tablerow">
              <th>ID</th>
              <th>DATE</th>
              <th>METHOD</th>
              <th>CLOSING BALANCE</th>
              <th>AMOUNT</th>
            </tr>
          </thead>

          <tbody>
            {transaction?.map((item, index) => (
              <tr className="tablerow" key={index}>
                <td className="tablehead">{item?.id}</td>
                <td className="tablehead">{item?.date}</td>
                <td className="tablehead">{item?.method}</td>
                <td className="tablehead">{item?.closing_balance}</td>
                <td className="tablehead">{item?.amount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="logout-button-section mt-5">
        <button className="logout-button" onClick={handleLogout}>
          <span>log out</span>
        </button>
      </div>
    </>
  );
};

export default MyAccount;
