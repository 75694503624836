import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./messageSlice";

import AuthService from "../../services/auth.service";
import { getCookie } from "../../services/cookies.service";

const user = JSON.parse(getCookie("user"));

export const register = createAsyncThunk(
  "auth/register",
  async (payload, thunkAPI) => {
    try {
      const response = await AuthService.register(payload);
      thunkAPI.dispatch(setMessage(response?.message));
      return response?.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const login = createAsyncThunk(
  "auth/login",
  async (payload, thunkAPI) => {
    try {
      const data = await AuthService.login(payload);
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  },
);

export const logout = createAsyncThunk("auth/logout", async () => {
  await AuthService.logout();
});

export const updateUserAddress = createAsyncThunk(
  "auth/updateUserAddress",
  async (payload, thunkAPI) => {
    const data = await AuthService.updateUserAddress(payload);
    return data;
  },
);

export const updateUserAvatar = createAsyncThunk(
  "auth/updateUserAvatar",
  async (payload, thunkAPI) => {
    const response = await AuthService.updateUserAvatar(payload);
    return response;
  },
);

const initialState = user
  ? { isLoggedIn: true, user }
  : { isLoggedIn: false, user: null };

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: {
    [register.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
    },
    [register.rejected]: (state, action) => {
      state.isLoggedIn = false;
    },
    [login.fulfilled]: (state, action) => {
      state.user = action?.payload?.user;
      if (state?.user?.token) {
        state.isLoggedIn = true;
      }
    },
    [login.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [logout.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [updateUserAddress.fulfilled]: (state, action) => {
      state.user.address = action?.payload?.data?.data[0];
    },
    [updateUserAvatar.fulfilled]: (state, action) => {
      state.user.avatar = action?.payload?.data?.data;
    },
  },
});

const { reducer } = authSlice;
export default reducer;
