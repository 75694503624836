import React from "react";
import { SliderComp } from "./CarouselHome";
const HomePageCarousel = () => {
  return (
    <>
      <div className="mt-3" style={{ position: "relative" }}>
        {/* <HomeCareousel /> */}
        <SliderComp />
      </div>
    </>
  );
};

export default HomePageCarousel;
