import React, { useState } from "react";
import "./ResetPassword.css";
import { LogIn } from "lucide-react";
import { Lock } from "lucide-react";
import Loading from "../../components/Loader/Loading";
import Toaster from "../../components/Toast/Toast";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import TextInput from "../../components/TextInput/TextInput";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { ChangePassword } from "../../api/modules/loothoot.class";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";

const ResetPassword = () => {
  const isDarkMode = useSelector((state) => state?.theme?.darkMode);
  const isLoading = useSelector((state) => state?.loader?.isLoading);
  const [error, setError] = useState({ error: "" });
  const { slugId } = useParams();
  const navigate = useNavigate();

  const initialValues = {
    password: "",
    confirm_password: "",
  };

  const ResetSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .min(8, `Password is too short - should be 8 chars minimum`),
    confirm_password: Yup.string()
      .required("Password is required")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  const handleSubmit = async (values) => {
    console.log(slugId, values);
    try {
      const response = await ChangePassword(slugId, {
        password: values.password,
        password_confirmation: values.confirm_password,
      });
      console.log(response);
      if (response?.data?.success) {
        navigate(routes.HOME);
      }
      setError(response?.error || response?.message || response?.data?.message);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="py-5">
      <Toaster message={error} />
      <div className="d-flex justify-content-center align-items-center">
        <h4>Reset Password</h4>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={ResetSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form className="loginFieldArea">
            <div className="NEWloginElement">
              <TextInput
                isDarkMode={isDarkMode}
                type="password"
                name="password"
                placeholder="Password"
                onChange={handleChange}
                value={values.password}
                padding={40}
              />
              <Lock
                className={`login-icon ${
                  errors.password && touched.password ? "error-icon" : ""
                }`}
              />
              <span className="error">
                {errors.password && touched.password && errors.password}
              </span>
            </div>
            <div className="NEWloginElement">
              <TextInput
                isDarkMode={isDarkMode}
                type="password"
                name="confirm_password"
                placeholder="Confirm Password"
                onChange={handleChange}
                value={values.confirm_password}
                padding={40}
              />
              <Lock
                className={`login-icon ${
                  errors.confirm_password && touched.confirm_password
                    ? "error-icon"
                    : ""
                }`}
              />
              <span className="error">
                {errors.confirm_password &&
                  touched.confirm_password &&
                  errors.confirm_password}
              </span>
            </div>

            <div className="modal-footer-butttons w-50">
              <button className="signup-btn" onClick={handleSubmit}>
                {isLoading && <Loading />}
                <LogIn alt="" style={{ color: "white", width: "17px" }} />
                <span>Reset Password</span>
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ResetPassword;
