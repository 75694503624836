import React, { useEffect, useState } from "react";
import "./shippingaddress.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import TextInput from "../../components/TextInput/TextInput";
import { postUserDetils, userDetail } from "../../api/modules/loothoot.class";
import Toaster from "../Toast/Toast";
import { updateUserAddress } from "../../redux/slices/authSlice";

const ShippingAddress = () => {
  const user = useSelector((state) => state?.auth?.user);
  const [error, setError] = useState({ success: "", error: "" });
  const dispatch = useDispatch();

  const initialValues = {
    username: user?.address?.username || "",
    address_1: user?.address?.address_1 || "",
    address_2: user?.address?.address_2 || "",
    postal_code: user?.address?.postal_code || "",
    state: user?.address?.state || "",
    city: user?.address?.city || "",
    country: user?.address?.country || "",
    phone_number: user?.address?.phone_number || "",
  };

  const SignupSchema = Yup.object().shape({
    username: Yup.string().required("required"),
    address_1: Yup.string().required("required"),
    postal_code: Yup.string()
      .length(5)
      .matches(/^[0-9]{5}/)
      .required(),
    state: Yup.string().required("required"),
    city: Yup.string().required("required"),
    country: Yup.string().required("required"),
    phone_number: Yup.string().required("Phone number is required"),
  });
  const handleSubmit = (values) => {
    dispatch(updateUserAddress({ ...values })).then((response) => {
      setError((prev) => ({
        ...prev,
        success: response?.data?.message || "Record updated successfully!",
        error: response?.data?.error?.message || "",
      }));
    });
  };
  const [editing, setEditing] = useState(false);

  const handleEditClick = () => {
    setEditing(true);
  };

  const handleHideClick = () => {
    setEditing(false);
  };
  const isDarkMode = useSelector((state) => state.theme.darkMode);

  return (
    <>
      <Toaster message={error} />
      <div className="shipping-title ">
        <h2>Shipping Address</h2>
        {editing ? (
          <button
            type=""
            onClick={handleHideClick}
            className="hide-change-btn hide-btn"
          >
            Hide
          </button>
        ) : (
          <button
            type=""
            onClick={handleEditClick}
            className="hide-change-btn change-btn"
          >
            Change
          </button>
        )}
      </div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={SignupSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form className="shipping-address-form " onSubmit={handleSubmit}>
            <div className="shipping-address-textfeilds">
              <div className="loginElement">
                <TextInput
                  isDarkMode={isDarkMode}
                  type="username"
                  name="username"
                  placeholder="Full Name"
                  label="Full Name"
                  onChange={handleChange}
                  value={values?.username}
                  disabled={!editing}
                />

                <span className="error">
                  {errors.username && touched.username && errors.username}
                </span>
              </div>

              <div className="loginElement">
                <TextInput
                  isDarkMode={isDarkMode}
                  type="address_1"
                  name="address_1"
                  label="Address"
                  placeholder="Address"
                  onChange={handleChange}
                  value={values?.address_1}
                  disabled={!editing}
                />
                <span className="error">
                  {errors.address_1 && touched.address_1 && errors.address_1}
                </span>
              </div>
              <div className="loginElement">
                <TextInput
                  isDarkMode={isDarkMode}
                  type="address_2"
                  name="address_2"
                  label="Address 2"
                  placeholder="Address 2"
                  onChange={handleChange}
                  value={values?.address_2}
                  disabled={!editing}
                />
                <span className="error">
                  {errors?.address_2 && touched?.address_2 && errors?.address_2}
                </span>
              </div>
              <div className="loginElement">
                <TextInput
                  isDarkMode={isDarkMode}
                  type="postal_code"
                  name="postal_code"
                  label="Postal code / Zip code"
                  placeholder="Postal code / Zip code"
                  onChange={handleChange}
                  value={values?.postal_code}
                  disabled={!editing}
                />
                <span className="error">
                  {errors.postal_code &&
                    touched.postal_code &&
                    errors.postal_code}
                </span>
              </div>
              <div className="loginElement">
                <TextInput
                  isDarkMode={isDarkMode}
                  type="state"
                  name="state"
                  label="State / Province / Region"
                  placeholder="State / Province / Region"
                  onChange={handleChange}
                  value={values?.state}
                  disabled={!editing}
                />
                <span className="error">
                  {errors.state && touched.state && errors.state}
                </span>
              </div>
              <div className="loginElement">
                <TextInput
                  isDarkMode={isDarkMode}
                  type="city"
                  name="city"
                  label="City"
                  placeholder="City"
                  onChange={handleChange}
                  value={values?.city}
                  disabled={!editing}
                />
                <span className="error">
                  {errors.city && touched.city && errors.city}
                </span>
              </div>
              <div className="loginElement">
                <TextInput
                  isDarkMode={isDarkMode}
                  type="country"
                  name="country"
                  label="Country"
                  placeholder="Country"
                  onChange={handleChange}
                  value={values?.country}
                  disabled={!editing}
                />
                <span className="error">
                  {errors.country && touched.country && errors.country}
                </span>
              </div>
              <div className="loginElement">
                <TextInput
                  isDarkMode={isDarkMode}
                  type="phone_number"
                  name="phone_number"
                  label="Phone (Including Country Code)"
                  placeholder="Phone Number
                  "
                  onChange={handleChange}
                  value={values?.phone_number}
                  disabled={!editing}
                />
                <span className="error">
                  {errors.phone_number &&
                    touched.phone_number &&
                    errors.phone_number}
                </span>
              </div>
            </div>
            <button
              className="save-info-button"
              type="submit"
              onClick={handleSubmit}
            >
              <span>Save Address</span>
            </button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ShippingAddress;
