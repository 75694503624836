import React, { useState, useEffect } from "react";
import "./createBatlle.css";
import arrowbackdark from "../../assets/images/arrowbackdark.png";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomSelect from "../../components/Select/Select";
import { useSelector } from "react-redux";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import { AiOutlinePlus } from "react-icons/ai";
import MyVerticallyCenteredModal from "./BattleModal";
import { routes } from "../../routes";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { postBattles } from "../../api/modules/loothoot.class";
import TopUpModal from "../../components/TopupModal/TopUpModal";
import AddBox from "./AddBox";
import { allBoxes, boxCategory } from "../../api/modules/loothoot.class";

const CreateBattle = () => {
  const navigate = useNavigate();
  const [totalBoxes, setTotalBoxes] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [topupmodalShow, setTopUpModalShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [selectedCards, setSelectedCards] = useState([]);
  const userid = useSelector((state) => state?.auth?.user?.id);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState(null);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    try {
      const api = async () => {
        const allresponse = await allBoxes();
        setData(allresponse?.data?.data);

        setLoading(false);
        if (selected) {
          if (selected.value === "Featured") {
            const response = await boxCategory("Featured");
            setData(response?.data?.data?.data[0]?.box);
            setLoading(false);
          } else if (selected.value === "Hot") {
            const response = await boxCategory("Hot");
            setData(response?.data?.data?.data[0]?.box);
            setLoading(false);
          } else if (selected.value === "Updated") {
            const response = await boxCategory("Updated");
            setData(response?.data?.data?.data[0]?.box);
            setLoading(false);
          }
        }
      };

      api();
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }, [selected]);

  const typeOptions = [{ value: "option1", label: "1 * 1" }];

  const isDarkMode = useSelector((state) => state.theme.darkMode);
  const validationSchema = Yup.object().shape({
    private_battle: Yup.boolean().required("Toggle 1 is required"),
    bot: Yup.boolean().required("Toggle 2 is required"),
    crazy_mod: Yup.boolean().required("Toggle 3 is required"),
  });

  const initialValues = {
    private_battle: false,
    bot: false,
    crazy_mod: false,
  };

  const handleToggleChange = (formik, field, value) => {
    formik.setFieldValue(field, value);
  };

  const updateOrCreateObject = (value) => {
    const existingIndex = data.findIndex((item) => item?.id === value?.id);
    if (existingIndex !== -1) {
      const updatedData = [...data];
      updatedData[existingIndex] = { ...value };
      setSelectedCards(updatedData);
    } else {
      setSelectedCards([...data, value]);
    }
  };

  const handleAddToBattleClick = (index) => {
    const newData = [...data];
    if (newData[index].quantity > 0) {
      newData[index].quantity += 1;
    } else {
      newData[index].quantity = 1;
    }
    setData(newData);
    setTotalBoxes((prev) => prev + 1);
    setTotalPrice(
      (prev) => prev + parseFloat(newData[index]?.box_price.replace("$", "")),
    );
    updateOrCreateObject(newData[index]);
  };

  const handlePlusClick = (index) => {
    const newData = [...data];
    newData[index].quantity += 1;
    setData(newData);
    setTotalPrice(
      (prev) => prev + parseFloat(newData[index]?.box_price.replace("$", "")),
    );
    updateOrCreateObject(newData[index]);
  };

  const handleMinusClick = (index) => {
    const newData = [...data];
    if (newData[index].quantity === 1) {
      newData[index].quantity = 0;
      setData(newData);
      setTotalBoxes((prev) => prev - 1);
      setTotalPrice(
        (prev) => prev - parseFloat(newData[index]?.box_price.replace("$", "")),
      );
    } else if (newData[index].quantity > 1) {
      newData[index].quantity -= 1;
      setData(newData);
      setTotalPrice(
        (prev) => prev - parseFloat(newData[index]?.box_price.replace("$", "")),
      );
    }
    updateOrCreateObject(newData[index]);
  };

  const handleSubmit = (values) => {
    setDisabled(true);
    let newArray = [];
    selectedCards?.forEach((item) => {
      if (item?.quantity) {
        newArray.push({ box_id: item?.id, quantity: item?.quantity });
      }
    });
    try {
      const api = async () => {
        const response = await postBattles({
          ...values,
          battle_player_id: 1,
          users_id: userid,
          boxes: newArray,
        });

        if (!response?.data?.exception && response?.data?.success) {
          navigate(routes.BATTLES);
        } else {
          setDisabled(false);
        }
      };

      api();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setDisabled(totalBoxes <= 0 ? true : false);
  }, [totalBoxes]);

  return (
    <>
      <div className="all-battles-main mt-3">
        <div className="all-battles-header">
          <button onClick={() => navigate(routes.BATTLES)}>
            <img src={arrowbackdark} alt="" />
            <span>Back</span>
          </button>
          <h2>CREATE BOX BATTLE</h2>
          {/* <button
            className="start-battle"
            onClick={() => setTopUpModalShow(true)}
          >
            <span>Start Battle For ${totalPrice}</span>
          </button> */}
        </div>
        <div className="mt-4 battles-pricing">
          <span>BOXES: {totalBoxes}</span> •
          <span>TOTAL VALUE: ${totalPrice}</span>
        </div>
        <div className="mt-4">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {(formik) => (
              <Form>
                <div className="Toggles-input-feilds">
                  <div className="Battles-toggles-checkbox">
                    <label htmlFor="private_battle"> Private battle</label>
                    <Toggle
                      icons={false}
                      id="private_battle"
                      checked={formik.values.private_battle}
                      onChange={(e) =>
                        handleToggleChange(
                          formik,
                          "private_battle",
                          e.target.checked,
                        )
                      }
                    />
                    {formik.touched.private_battle &&
                      formik.errors.private_battle && (
                        <div>{formik.errors.private_battle}</div>
                      )}
                  </div>

                  {/* <div className="Battles-toggles-checkbox">
                    <label htmlFor="bot">Play with bot</label>
                    <Toggle
                      icons={false}
                      id="bot"
                      checked={formik.values.bot}
                      onChange={(e) =>
                        handleToggleChange(formik, "bot", e.target.checked)
                      }
                    />
                    {formik.touched.bot && formik.errors.bot && (
                      <div>{formik.errors.bot}</div>
                    )}
                  </div> */}

                  <div className="Battles-toggles-checkbox">
                    <label htmlFor="crazy_mod">Crazy mode</label>
                    <Toggle
                      icons={false}
                      id="crazy_mod"
                      checked={formik.values.crazy_mod}
                      onChange={(e) =>
                        handleToggleChange(
                          formik,
                          "crazy_mod",
                          e.target.checked,
                        )
                      }
                    />
                    {formik.touched.crazy_mod && formik.errors.crazy_mod && (
                      <div>{formik.errors.crazy_mod}</div>
                    )}
                  </div>

                  <button
                    type="submit"
                    className="start-battle"
                    disabled={disabled}
                  >
                    <span>Start Battle For ${totalPrice.toFixed(2)}</span>
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div>
          <div className="loginElement p-0">
            <label
              className={isDarkMode ? "label label-dark" : " label label-light"}
            >
              TYPES
            </label>
            <CustomSelect options={typeOptions} isMulti={false} />
          </div>
        </div>
        <div className="d-flex justify-content-center mt-5">
          <div
            onClick={() => setModalShow(true)}
            className={
              isDarkMode
                ? "addBox-dark addBox col-md-4"
                : "addBox-light addBox col-md-4"
            }
          >
            <AiOutlinePlus className="plus-icon-battles" />
            Add A Box
          </div>
        </div>

        <Container>
          <Row>
            {selectedCards?.map((item, index) =>
              item?.quantity > 0 ? (
                <Col md={3}>
                  <AddBox
                    item={item}
                    index={index}
                    isDarkMode={isDarkMode}
                    handleAddToBattleClick={handleAddToBattleClick}
                    handlePlusClick={handlePlusClick}
                    handleMinusClick={handleMinusClick}
                  />
                </Col>
              ) : null,
            )}
          </Row>
        </Container>
        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          handleAddToBattleClick={handleAddToBattleClick}
          handlePlusClick={handlePlusClick}
          handleMinusClick={handleMinusClick}
          selected={selected}
          setSelected={setSelected}
          loading={loading}
          setLoading={setLoading}
          data={data}
          totalPrice={totalPrice}
          totalBoxes={totalBoxes}
        />
        <TopUpModal
          show={topupmodalShow}
          setTopUpModalShow={setTopUpModalShow}
        />
      </div>
    </>
  );
};

export default CreateBattle;
