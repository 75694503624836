import React, { useEffect, useState } from "react";
import { Navbar, Nav} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "./header.css";
import { routes } from "../../../routes";
import logoLight from "../../../assets/images/logoLight.png";
import logoDark from "../../../assets/images/logoDark.png";
import { useLocation } from "react-router-dom";
import { Box } from "lucide-react";
import { Flame } from "lucide-react";
import { Zap } from "lucide-react";
import { Users } from "lucide-react";
import { Gift } from "lucide-react";
import { UserPlus } from "lucide-react";
import { Plus } from "lucide-react";

import { DarkModeSwitch } from "react-toggle-dark-mode";
import miniLogoLight from "../../../assets/images/logo/black.png";
import miniLogoDark from "../../../assets/images/logo/red.png";
import { useSelector, useDispatch } from "react-redux";
import { toggleTheme } from "../../../redux/slices/themeSlice";
import { toggleOnboard } from "../../../redux/slices/onBoardingSlice";
import Register from "../../../pages/register/Signup";
import Login from "../../../pages/login/Login";
import DropDown from "../../dropDown/DropDown";
import { BsCartCheck } from "react-icons/bs";
import WalletModal from "./walletModal/WalletModal";
import { GetBalance } from "../../../api/modules/loothoot.class";
import { setAccount, togglePayment } from "../../../redux/slices/accountSlice";
const NavBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isDarkMode = useSelector((state) => state?.theme?.darkMode);
  const isLoggedIn = useSelector((state) => state?.auth?.isLoggedIn);
  const balance = useSelector((state) => state?.account?.balance);
  const payment = useSelector((state) => state?.account?.payment);
  const [expanded, setExpanded] = useState(false);
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(
    window.innerWidth < 992,
  );
  const [isMobileLogo, setIsMobileLogo] = useState(window.innerWidth < 460);

  const handleNavItemClick = () => {
    setExpanded(false);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 992) {
        setIsMobileOrTablet(true);
      } else {
        setIsMobileOrTablet(false);
      }
      if (window.innerWidth < 460) {
        setIsMobileLogo(true);
      } else {
        setIsMobileLogo(false);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    let intervalCall;
    const getbalance = async () => {
      try {
        const response = await GetBalance();
        if (response?.data?.data?.balance) {
          dispatch(setAccount({ balance: response?.data?.data?.balance }));
        }
      } catch (err) {
        console.log(err);
      }
    };
    if (isLoggedIn) {
      intervalCall = setInterval(() => {
        getbalance();
      }, 3000);
    }

    return () => {
      clearInterval(intervalCall);
    };
  }, [isLoggedIn, dispatch]);
  const openWithdrawModal = () => {
    dispatch(togglePayment({ payment: true }));
  };
  return (
    <>
      <Navbar
        style={{ justifyContent: isMobileLogo ? "center" : "end" }}
        expand="lg"
        className={`${
          isDarkMode
            ? "AppDark navbar-dark px-1 customClass"
            : "AppLight customClass"
        }`}
        expanded={expanded}
        onToggle={() => setExpanded(!expanded)}
      >
        <Navbar.Brand className="toggle-buttons p-1 px-4">
          {isMobileOrTablet && (
            <span className="">
              <Link to={routes.HOME}>
                {isMobileLogo ? (
                  <img
                    src={isDarkMode ? miniLogoDark : miniLogoLight}
                    width={80}
                  />
                ) : (
                  <img src={isDarkMode ? logoDark : logoLight} width={125} />
                )}
              </Link>
            </span>
          )}
        </Navbar.Brand>

        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          style={{ marginRight: "5px" }}
        />
        <Navbar.Offcanvas
          style={{
            width: "200px",
            backgroundColor: isDarkMode ? "black" : "white",
          }}
        >
          <Navbar.Collapse id="basic-navbar-nav" className="headerlinks">
            <Nav className="mr-auto headerCol">
              <Nav.Link
                onClick={handleNavItemClick}
                className={`${
                  location?.pathname === routes?.HOME ||
                  location?.pathname === routes?.UNBOXES
                    ? isDarkMode
                      ? "selected-link-dark"
                      : "selected-link-light"
                    : isDarkMode
                    ? "headerDark"
                    : "headerLight"
                }`}
              >
                <div
                  className={
                    isDarkMode ? "headerOptionsDark" : "headerOptionsLight"
                  }
                  onClick={() => navigate(routes.UNBOXES)}
                >
                  <Box width={"15px"} />
                  <span>Unboxing</span>
                </div>
              </Nav.Link>
              <Nav.Link
                onClick={handleNavItemClick}
                className={`${
                  location?.pathname === routes?.BATTLES
                    ? isDarkMode
                      ? "selected-link-dark"
                      : "selected-link-light"
                    : isDarkMode
                    ? "headerDark"
                    : "headerLight"
                }`}
              >
                <div
                  className={
                    isDarkMode ? "headerOptionsDark" : "headerOptionsLight"
                  }
                  onClick={() => navigate(routes.BATTLES)}
                >
                  <Flame width={15} />
                  <span>Battles</span>
                </div>
              </Nav.Link>
              <Nav.Link
                onClick={handleNavItemClick}
                className={`${
                  location?.pathname === routes?.DEALS
                    ? isDarkMode
                      ? "selected-link-dark"
                      : "selected-link-light"
                    : isDarkMode
                    ? "headerDark"
                    : "headerLight"
                }`}
              >
                <div
                  className={
                    isDarkMode ? "headerOptionsDark" : "headerOptionsLight"
                  }
                  onClick={() => navigate(routes.DEALS)}
                >
                  <Zap width={15} />
                  <span>Deals</span>
                </div>
              </Nav.Link>
              {!isMobileOrTablet && (
                <Nav.Link className="px-4">
                  <Link to={routes.HOME}>
                    <img src={isDarkMode ? logoDark : logoLight} width={125} />
                  </Link>
                </Nav.Link>
              )}
              <Nav.Link
                onClick={handleNavItemClick}
                className={`${
                  location?.pathname === routes?.AFFLIATES
                    ? isDarkMode
                      ? "selected-link-dark"
                      : "selected-link-light"
                    : isDarkMode
                    ? "headerDark"
                    : "headerLight"
                }`}
              >
                <div
                  className={
                    isDarkMode ? "headerOptionsDark" : "headerOptionsLight"
                  }
                  onClick={() => navigate(routes.AFFLIATES)}
                >
                  <Users width={15} />
                  <span>Affiliates</span>
                </div>
              </Nav.Link>
              <Nav.Link
                onClick={handleNavItemClick}
                className={`${
                  location?.pathname === routes?.FREEDROP
                    ? isDarkMode
                      ? "selected-link-dark"
                      : "selected-link-light"
                    : isDarkMode
                    ? "headerDark"
                    : "headerLight"
                }`}
              >
                <div
                  className={
                    isDarkMode ? "headerOptionsDark" : "headerOptionsLight"
                  }
                  onClick={() => navigate(routes.FREEDROP)}
                >
                  <Gift width={15} />
                  <span>Free Drops</span>
                </div>
              </Nav.Link>
            </Nav>
            <div className="d-flex p-2 buttonSide ">
              {isMobileOrTablet && (
                <span className={isDarkMode ? "mx-2 themeText" : "mx-2"}>
                  THEME MODE
                </span>
              )}

              <div className="toggleIcon">
                <DarkModeSwitch
                  checked={isDarkMode}
                  onChange={() => dispatch(toggleTheme())}
                  size={20}
                />
              </div>
              {isLoggedIn ? (
                <>
                  <BsCartCheck
                    className={isDarkMode ? "navCartDark navCart" : "navCart"}
                    onClick={() => navigate(routes.CART)}
                  />

                  <DropDown isMobileLogo={isMobileLogo} />
                </>
              ) : (
                <>
                  <button
                    className={
                      isMobileOrTablet
                        ? "buttonOnToggle loginbutton"
                        : "loginbutton mx-2"
                    }
                    onClick={() => dispatch(toggleOnboard({ login: true }))}
                  >
                    Login
                  </button>

                  <button
                    className={
                      isMobileOrTablet
                        ? "buttonOnToggle SignUpbutton"
                        : "SignUpbutton"
                    }
                    onClick={() => dispatch(toggleOnboard({ register: true }))}
                  >
                    <UserPlus style={{ width: "15px", marginRight: "2px" }} />
                    Signup
                  </button>
                </>
              )}
            </div>
          </Navbar.Collapse>
        </Navbar.Offcanvas>

        {isLoggedIn && (
          <>
            <div className="account-balance-div">
              <div className="account-balance">
                <span>${balance ? balance : "0.00"}</span>
              </div>
              <a className="plus-icon" onClick={openWithdrawModal}>
                <Plus />
              </a>
            </div>
          </>
        )}
      </Navbar>

      <div>
        <Register />
        <Login />
      </div>
      {isLoggedIn && (
        <WalletModal show={payment} onHide={() => dispatch(togglePayment())} />
      )}
    </>
  );
};

export default NavBar;
