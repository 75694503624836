import React from "react";
import "./timeline.css";
import { useSelector } from "react-redux";
const TimeLine = ({ item, index }) => {
  const isDarkMode = useSelector((state) => state.theme.darkMode);
  return (
    <>
      <div className="container mt-1">
        <div className="row battle-boxes-timeline">
          <ul
            className={
              isDarkMode ? "timeline-dark timeline" : "timeline-light timeline"
            }
          >
            {item?.boxes?.map((box, x) => (
              <li>
                <div className={`timeline-content-${x < 3 ? x + 1 : 3}`}>
                  <img
                    src={`${process.env.REACT_APP_CDN_URL}/images/${box?.box_image?.image}`}
                    alt=""
                  />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default TimeLine;
