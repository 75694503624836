import React, { useEffect, useState } from "react";
import TextInput from "../../components/TextInput/TextInput";
import "./signup.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { routes } from "../../routes";
import { Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { register } from "../../redux/slices/authSlice";
import { clearMessage } from "../../redux/slices/messageSlice";
import { toggleOnboard } from "../../redux/slices/onBoardingSlice";
import { toggleLoader } from "../../redux/slices/loaderSlice";
import Loading from "../../components/Loader/Loading";
import Toaster from "../../components/Toast/Toast";
import { UserPlus } from "lucide-react";
import { Mail } from "lucide-react";
import { Lock } from "lucide-react";
const Register = ({ handleClose, show, handleShowLogin }) => {
  const onboarding = useSelector((state) => state?.onboarding);
  const isLoading = useSelector((state) => state?.loader?.isLoading);
  const dispatch = useDispatch();
  const [error, setError] = useState({ success: "", error: "" });

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  const isDarkMode = useSelector((state) => state.theme.darkMode);

  const initialValues = {
    email: "",
    password: "",
  };

  const SignupSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email address").required("Required"),
    password: Yup.string()
      .required("Password is required")
      .min(8, `Password is too short - should be 8 chars minimum`),
  });

  const handleSubmit = (values) => {
    dispatch(toggleLoader({ isLoading: true }));

    dispatch(register(values))
      .unwrap()
      .then((response) => {
        dispatch(toggleLoader({ isLoading: false }));
        setError((prev) => ({
          ...prev,
          success: response?.message || "",

          error: response?.error?.message || "",
        }));

        if (response?.user?.id) {
          dispatch(toggleOnboard({ login: true }));
        }
      })
      .catch((error) => {
        setError(error?.message);
        console.log("error", error);
        dispatch(toggleLoader({ isLoading: false }));
      });
  };

  return (
    <>
      <Toaster message={error} />
      <Modal
        show={onboarding?.register}
        onHide={() => dispatch(toggleOnboard())}
        size="md"
        className={isDarkMode ? "modal-content-dark" : "modal-content-light"}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div
            className={
              isDarkMode
                ? " signup-head-dark signup-head"
                : "signup-head-light signup-head"
            }
          >
            <p>Signup</p>
          </div>
          <div className="signup-modal-body">
            <Formik
              initialValues={initialValues}
              validationSchema={SignupSchema}
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                isSubmitting,
              }) => (
                <Form className="loginFieldArea">
                  <div className="NEWloginElement">
                    <TextInput
                      isDarkMode={isDarkMode}
                      type="email"
                      name="email"
                      placeholder="Email address"
                      onChange={handleChange}
                      value={values.email}
                      padding={40}
                    />
                    <Mail
                      className={`login-icon ${
                        errors.email && touched.email ? "error-icon" : ""
                      }`}
                    />
                    <span className="error">
                      {errors.email && touched.email && errors.email}
                    </span>
                  </div>
                  <div className="NEWloginElement">
                    <TextInput
                      isDarkMode={isDarkMode}
                      type="password"
                      name="password"
                      placeholder="Set password"
                      onChange={handleChange}
                      value={values.password}
                      padding={40}
                    />
                    <Lock
                      className={`login-icon ${
                        errors.password && touched.password ? "error-icon" : ""
                      }`}
                    />
                    <span className="error">
                      {errors.password && touched.password && errors.password}
                    </span>
                  </div>

                  <div className="modal-body-checkbox">
                    <div
                      className={
                        isDarkMode
                          ? "modal-checkbox-div-dark modal-checkbox-div"
                          : "modal-checkbox-div-light modal-checkbox-div"
                      }
                    >
                      <input type="checkbox" className="modal-checkbox" />
                      <span>
                        I'd like to receive valuable promotions via email
                      </span>
                    </div>
                    <div
                      className={
                        isDarkMode
                          ? "modal-checkbox-div-dark modal-checkbox-div"
                          : "modal-checkbox-div-light modal-checkbox-div"
                      }
                    >
                      <input type="checkbox" className="modal-checkbox" />
                      <span>
                        By signing up I agree to the LootHoot{" "}
                        <Link className="privacylink" to={routes?.PRIVACYPAYMENTS}>
                          Privacy Policy
                        </Link>{" "}
                        , and{" "}
                        <Link className="privacylink" to={routes?.TERMS}>
                          {" "}
                          Terms of Use
                        </Link>
                        . I also declare that I am 18 years or older.
                      </span>
                    </div>
                  </div>

                  <hr className="modal-signup-hr" />

                  {/* <div className="modal-social-main modal-social-main-stream">
                    <div className="modal-social-stream">
                      <img src={stream} alt="" />
                      <span>Stream</span>
                    </div>
                  </div>
                  <div className="modal-social-main modal-social-main-google">
                    <div className="modal-social-stream">
                      <img src={google} alt="" />
                      <span>Google</span>
                    </div>
                  </div>
                  <div className="modal-social-main modal-social-main-facebook">
                    <div className="modal-social-stream">
                      <img src={facebook} alt="" />
                      <span>Facebook</span>
                    </div>
                  </div> */}

                  <div className="modal-footer-butttons">
                    <button className="signup-btn" onClick={handleSubmit}>
                      {isLoading && <Loading />}
                      <UserPlus style={{ color: "white" }} />
                      <span>Signup</span>
                    </button>
                    <button
                      type="button"
                      className={
                        isDarkMode
                          ? " login-btn-dark login-btn"
                          : "login-btn-light login-btn"
                      }
                      onClick={() => dispatch(toggleOnboard({ login: true }))}
                    >
                      Login
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Register;
