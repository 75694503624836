import React, { useState, useEffect } from "react";
import { routes } from "../../routes";
import { useNavigate } from "react-router";
import { AiFillSafetyCertificate } from "react-icons/ai";
import { useSelector } from "react-redux";

const BattleHeader = ({ battleDetail }) => {
  const navigate = useNavigate();
  const isDarkMode = useSelector((state) => state?.theme?.darkMode);
  const [boxDetail, setBoxDetail] = useState();

  useEffect(() => {
    if (battleDetail) {
      setBoxDetail(battleDetail);
    }
  }, [battleDetail]);

  return (
    <div className={isDarkMode ? "battle-header-dark" : "battle-header"}>
      <div className="battle-header-inner">
        <button
          className="battle-back-btn"
          onClick={() => navigate(routes.BATTLES)}
        >
          Back
        </button>
        <div>
          <h2>
            Box {`${boxDetail?.current_box}`} of {boxDetail?.total_box}
          </h2>
          <h6>Battle price: ${boxDetail?.battle_price}</h6>
        </div>
        <div className="d-flex justify-content-center align-items-center cursor-pointer">
          <img
            alt="box"
            width={100}
            src={`${process.env.REACT_APP_CDN_URL}/images/${boxDetail?.box?.box_image?.image}`}
          />
          <div>
            <h4>{boxDetail?.box?.name}</h4>
            <h6>${boxDetail?.box?.box_price}</h6>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center cursor-pointer">
          {/* <h6>
            <AiFillSafetyCertificate /> Provably Fair
          </h6> */}
        </div>
      </div>
    </div>
  );
};

export default BattleHeader;
