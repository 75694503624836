import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import "./spinmodal.css";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import { Modal } from "react-bootstrap";

import closemodal from "../../assets/icons/close-modal.png";
import { BsCartCheck } from "react-icons/bs";

const SpinModel = ({ setShowSpin, showSpin, winner, itemsData }) => {
  const isDarkMode = useSelector((state) => state?.theme?.darkMode);
  const [data, setData] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    itemsData?.forEach((item) => {
      if (winner?.winnerId === item?.id) {
        setData(item);
      }
    });
  }, [showSpin, itemsData, winner]);

  return (
    <>
      <Modal
        show={showSpin}
        onHide={() => setShowSpin(!showSpin)}
        size="md"
        className={isDarkMode ? "modal-content-dark" : "modal-content-light"}
      >
        <Modal.Header className="spinnermodalhead">
          <div>
            <img
              src={closemodal}
              alt=""
              onClick={() => setShowSpin(!showSpin)}
              className="close-modal"
            />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div
            className={
              isDarkMode
                ? " signup-head-dark spinner-head"
                : "signup-head-light spinner-head"
            }
          >
            <p className="text-center">WINNER of {data?.name}</p>
            <span className="text-center">{data?.description}</span>
          </div>

          <div className="spinnerModalMainDiv" style={{ maxHeight: "350px" }}>
            <img
              className="cardimg-unboxmodal"
              variant="top"
              width={500}
              src={`${process.env.REACT_APP_CDN_URL}/images/${data?.image}`}
              alt=" "
            />
            <p
              className={
                isDarkMode ? "product-price-dark" : "product-price-light"
              }
            >
              {" "}
              {`Product Price: ${data?.price}`}
            </p>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <button
              style={{ borderRadius: "15px" }}
              className="spinnerButton"
              onClick={() => navigate(routes.CART)}
            >
              <BsCartCheck className="navCart" />
              <span>Go to cart</span>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SpinModel;
