import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import battle1 from "../../assets/images/Battles/battle1.png";
import battle2 from "../../assets/images/Battles/battle2.png";
import Battleicon from "../../assets/images/Battles/battleicon.png";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import FlippingCoin from "./FlippingCoin";
import { FlipCoin, FlipStatus } from "../../api/modules/loothoot.class";
import Countdown from "../countDown/CountDown";
import FlipCountDown from "./countDown/FlipCountDown";

const CoinFlip = ({ player1, player2, battle_id, setBattle }) => {
  const isDarkMode = useSelector((state) => state.theme.darkMode);
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const [coinClass, setCoinClass] = useState(null);
  const [showOtherComponent, setShowOtherComponent] = useState(false);

  const handleCountdownComplete = () => {
    setShowOtherComponent(true);
  };
  const api = async () => {
    try {
      const response = await FlipCoin({ battle_id: battle_id });

      if (response.data) {
        if (response?.data?.winner === player1?.user?.id) {
          setCoinClass("heads");
        } else setCoinClass("tails");

        FlipStatus({ battle_id: battle_id });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      api();
    }, 3000);
  }, []);

  return (
    <div
      className={isDarkMode ? "end-battle-detail-dark" : "end-battle-detail"}
    >
      <h1>It's a tie!</h1>
      <div className="mt-4 battle-end-icons">
        <div className="battle-end-icons-details">
          <img
            src={
              player1?.user?.userdetails?.avatar
                ? `${process.env.REACT_APP_CDN_URL}/images/${player1?.user?.userdetails?.avatar}`
                : battle1
            }
            alt=""
          />

          <span style={{ color: player1?.total_amount > 0 ? "red" : "black" }}>
            ${player1?.total_amount}
          </span>
        </div>
        <img src={Battleicon} alt="" />
        <div className="battle-end-icons-details">
          <img
            src={
              player2?.user?.userdetails?.avatar
                ? `${process.env.REACT_APP_CDN_URL}/images/${player2?.user?.userdetails?.avatar}`
                : battle2
            }
            alt=""
          />

          <span style={{ color: player2?.total_amount > 0 ? "red" : "black" }}>
            ${player2?.total_amount}
          </span>
        </div>
      </div>
      <div className="mt-3 FlippinRev">
        {coinClass ? (
          <FlippingCoin
            battle1={
              player1?.user?.userdetails?.avatar
                ? `${process.env.REACT_APP_CDN_URL}/images/${player1?.user?.userdetails?.avatar}`
                : battle1
            }
            battle2={
              player2?.user?.userdetails?.avatar
                ? `${process.env.REACT_APP_CDN_URL}/images/${player2?.user?.userdetails?.avatar}`
                : battle2
            }
            result={coinClass}
          />
        ) : !showOtherComponent ? (
          <FlipCountDown onComplete={handleCountdownComplete} height={""} />
        ) : (
          <></>
        )}
      </div>
      <div className="endBattleBtns mt-5">
        <button
          className="backtoBattlebtn p-3"
          onClick={() => navigate(routes.BATTLES)}
        >
          <span>Back to Battle List</span>
        </button>
      </div>
    </div>
  );
};

export default CoinFlip;
