import React, { useEffect, useState } from "react";
import TextInput from "../../components/TextInput/TextInput";
import "./login.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { login } from "../../redux/slices/authSlice";
import { clearMessage } from "../../redux/slices/messageSlice";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import closemodal from "../../assets/icons/close-modal.png";
import Loading from "../../components/Loader/Loading";
import Toaster from "../../components/Toast/Toast";
import { toggleOnboard } from "../../redux/slices/onBoardingSlice";
import { toggleLoader } from "../../redux/slices/loaderSlice";
import ForgetModal from "./FogetModal";
import { LogIn } from "lucide-react";
import { Mail } from "lucide-react";
import { Lock } from "lucide-react";

const Login = () => {
  const isDarkMode = useSelector((state) => state?.theme?.darkMode);
  const onboarding = useSelector((state) => state?.onboarding);
  const isLoading = useSelector((state) => state?.loader?.isLoading);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalShow, setModalShow] = React.useState(false);
  const [error, setError] = useState({ error: "" });

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  const initialValues = {
    email: "",
    password: "",
  };

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string()
      .required("Password is required")
      .min(8, `Password is too short - should be 8 chars minimum`),
  });

  const handleSubmit = (values) => {
    dispatch(toggleLoader({ isLoading: true }));
    dispatch(login(values))
      .unwrap()
      .then((response) => {
        dispatch(toggleLoader({ isLoading: false }));
        setError((prev) => ({
          ...prev,
          error: response?.error?.message || "",
        }));
        if (response?.token_key) {
          navigate("/");
          window.location.reload();
        }
      })
      .catch((error) => {
        setError((prev) => ({
          ...prev,
          error: error?.message || "",
        }));
        console.log("error", error);
        dispatch(toggleLoader({ isLoading: false }));
      });
  };
  const handleForgetModal = () => {
    setModalShow(true);
    dispatch(toggleOnboard());
  };
  return (
    <>
      <Toaster message={error} />

      <Modal
        show={onboarding?.login}
        onHide={() => dispatch(toggleOnboard())}
        size="md"
        className={isDarkMode ? "modal-content-dark" : "modal-content-light"}
      >
        <Modal.Header className="d-flex justify-content-between">
          <div
            className={
              isDarkMode
                ? " signup-head-dark signup-head"
                : "signup-head-light signup-head"
            }
          >
            <p>Login</p>
          </div>
          <div>
            <img
              src={closemodal}
              alt=""
              onClick={() => dispatch(toggleOnboard())}
              className="close-modal"
            />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="signup-modal-body">
            <Formik
              initialValues={initialValues}
              validationSchema={LoginSchema}
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                isSubmitting,
              }) => (
                <Form className="loginFieldArea">
                  <div className="NEWloginElement">
                    <TextInput
                      isDarkMode={isDarkMode}
                      type="email"
                      name="email"
                      placeholder="Email address"
                      onChange={handleChange}
                      value={values.email}
                      padding={40}
                    />
                    <Mail
                      className={`login-icon ${
                        errors.email && touched.email ? "error-icon" : ""
                      }`}
                    />
                    <span className="error">
                      {errors.email && touched.email && errors.email}
                    </span>
                  </div>
                  <div className="NEWloginElement">
                    <TextInput
                      isDarkMode={isDarkMode}
                      type="password"
                      name="password"
                      placeholder="Password"
                      onChange={handleChange}
                      value={values.password}
                      padding={40}
                    />
                    <Lock
                      className={`login-icon ${
                        errors.password && touched.password ? "error-icon" : ""
                      }`}
                    />
                    <span className="error">
                      {errors.password && touched.password && errors.password}
                    </span>
                  </div>
                  <button className="forgetpassword" onClick={handleForgetModal}>
                    Forgot Password? Click Me
                  </button>

                  {/* <span className="forget-password">
                    Forgot Password? Click Me
                  </span> */}

                  <hr className="modal-signup-hr" />

                  {/* <div className="modal-social-main modal-social-main-stream">
                    <div className="modal-social-stream">
                      <img src={stream} alt="" />
                      <span>Stream</span>
                    </div>
                  </div>
                  <div className="modal-social-main modal-social-main-google">
                    <div className="modal-social-stream">
                      <img src={google} alt="" />
                      <span>Google</span>
                    </div>
                  </div>
                  <div className="modal-social-main modal-social-main-facebook">
                    <div className="modal-social-stream">
                      <img src={facebook} alt="" />
                      <span>Facebook</span>
                    </div>
                  </div> */}

                  <div className="modal-footer-butttons">
                    <button className="signup-btn" onClick={handleSubmit}>
                      {isLoading && <Loading />}
                      <LogIn alt="" style={{ color: "white", width: "17px" }} />
                      <span>Login</span>
                    </button>
                    <button
                      type="button"
                      className={
                        isDarkMode
                          ? " login-btn-dark login-btn"
                          : "login-btn-light login-btn"
                      }
                      onClick={() =>
                        dispatch(toggleOnboard({ register: true }))
                      }
                    >
                      Signup
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
      <ForgetModal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

export default Login;
