import React from "react";

import { Button } from "react-bootstrap";

import MyaccountList from "../../components/myaccount-lists/MyaccountList";
import { useNavigate } from "react-router-dom";
import "./gamehistory.css";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/slices/authSlice";
const GameHistory = () => {
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout());
  };
  const navigate = useNavigate();

  const handleButtonClick = (buttonName) => {
    navigate(`/game-history/${buttonName}`);
  };

  return (
    <>
      {/* <Dashboard /> */}
      <MyaccountList />
      <div className="game-main">
        <div>
          <h1 className="game-heading">Game history</h1>
        </div>
        <div className="game-buttons-main">
          <Button
            bsPrefix="game-history-list"
            onClick={() => handleButtonClick("pvp")}
          >
            <span> PVP</span>
          </Button>
          <Button
            bsPrefix="game-history-list"
            onClick={() => handleButtonClick("unbox")}
          >
            <span> UNBOXING HISTORY</span>
          </Button>
          <Button
            bsPrefix="game-history-list"
            onClick={() => handleButtonClick("deals")}
          >
            <span>DEALS</span>
          </Button>
        </div>
      </div>
      <div className="logout-button-section mt-5">
        <button className="logout-button" onClick={handleLogout}>
          <span>log out</span>
        </button>
      </div>
    </>
  );
};

export default GameHistory;
