import React, { useEffect, useState } from "react";
import { TermsHtml } from "../../api/modules/loothoot.class";
import FullAppLoader from "../../components/Loader/FullAppLoader";
import { useDispatch, useSelector } from "react-redux";
import { toggleLoader } from "../../redux/slices/loaderSlice";
import { Container } from "react-bootstrap";

const Terms = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state?.loader?.isLoading);
  const [terms, setTerms] = useState();
  useEffect(() => {
    try {
      dispatch(toggleLoader({ isLoading: true }));
      TermsHtml()
        .then((response) => {
          setTerms(response?.data?.data);

          dispatch(toggleLoader({ isLoading: false }));
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log("Failed", e);
    }
  }, [dispatch]);
  return isLoading ? (
    <FullAppLoader />
  ) : (
    <Container className="px-5 py-5">
      <h1 className="text-center p-3">Terms of Use</h1>
      {terms
        ? terms?.map((item, index) => (
            <div dangerouslySetInnerHTML={{ __html: item?.description }} />
          ))
        : null}
    </Container>
  );
};

export default Terms;
