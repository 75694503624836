import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import DepositAddress from "./DepositAddress";

const DepositTab = ({ coin }) => {
  const [clickedDiv, setClickedDiv] = useState(coin[0]);
  const isDarkMode = useSelector((state) => state.theme.darkMode);

  const handleDivClick = (divName) => {
    setClickedDiv(divName);
  };

  return (
    <>
      <Container>
        <Row>
          <Col md={4} className="hr-withdraw">
            <div className="extra-cards-main">
              {coin?.map((item, index) => (
                <div
                  onClick={() => handleDivClick(item)}
                  className={
                    isDarkMode
                      ? " cards-div-dark cards-div"
                      : "cards-div-light cards-div"
                  }
                >
                  <img src={item?.img} alt="" className="payment-card-img" />
                  <span>{item?.title} </span>
                </div>
              ))}
            </div>
          </Col>
          <Col md={7}>
            <DepositAddress item={clickedDiv} />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DepositTab;
