import React, { useState } from "react";
import "./contact.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import TextInput from "../../components/TextInput/TextInput";
import { ContactUs } from "../../api/modules/loothoot.class";
import Toaster from "../../components/Toast/Toast";
import { Row, Col } from "react-bootstrap";

const Contact = () => {
  const [error, setError] = useState({ success: "", error: "" });
  const isDarkMode = useSelector((state) => state.theme.darkMode);
  
  const initialValues = {
    email: "",
    message: "",
    first_name: "",
    last_name: "",
    contact_number: "",
  };

  const SignupSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    message: Yup.string()
      .min(15, "Must be at least 15 characters")
      .required("Required"),
    first_name: Yup.string().required("First  Name is required"),
    last_name: Yup.string().required("Last  Name is required"),
    contact_number: Yup.string().required("Contact number is required"),
  });
  const handleSubmit = (values, { resetForm }) => {
    try {
      const api = async () => {
        const res = ContactUs(values);
        res
          .then((result) => {})
          .catch((err) => {
            console.log("err", err);
          });

        setError((prev) => ({
          ...prev,
          success: res?.data?.message || "Request Submitted Successfully",
          error: res?.data?.error?.message || "",
        }));
      };
      api();
      resetForm();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Toaster message={error} />
      <div className="contact-main">
        <h1>SUPPORT</h1>
        <div className="contact-p">
          <p className="mt-4">
            Before submitting a support ticket, first check if you find the
            answer to your question here: Frequently Asked Questions
          </p>
          <p>
            Please fill out the contact form below with your correct email
            address and a detailed description of your request.
          </p>
          <p>
            Our team will work to respond to your ticket within 24 hours.
            Responses may take longer due to occasional heavy traffic.
          </p>
        </div>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={SignupSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form className="contact-form " onSubmit={handleSubmit}>
              <div className="contactus-textfeilds">
                <Row>
                  <Col className="mt-2" md={6}>
                    <div>
                      <TextInput
                        isDarkMode={isDarkMode}
                        type="text"
                        name="first_name"
                        placeholder="Enter your First Name"
                        label="First Name"
                        onChange={handleChange}
                        value={values?.first_name}
                        style={{ width: "100%" }}
                      />

                      <span className="error">
                        {errors?.first_name &&
                          touched?.first_name &&
                          errors?.first_name}
                      </span>
                    </div>
                  </Col>
                  <Col className="mt-2" md={6}>
                    <div>
                      <TextInput
                        isDarkMode={isDarkMode}
                        type="text"
                        name="last_name"
                        placeholder="Enter your Last Name"
                        label="Last Name"
                        onChange={handleChange}
                        value={values?.last_name}
                        style={{ width: "100%" }}
                      />

                      <span className="error">
                        {errors?.last_name &&
                          touched?.last_name &&
                          errors?.last_name}
                      </span>
                    </div>
                  </Col>
                  <Col className="mt-2" md={6}>
                    <div>
                      <TextInput
                        isDarkMode={isDarkMode}
                        type="email"
                        name="email"
                        placeholder="Email Address"
                        label="Email"
                        onChange={handleChange}
                        value={values?.email}
                        style={{ width: "100%" }}
                      />

                      <span className="error">
                        {errors?.email && touched?.email && errors?.email}
                      </span>
                    </div>
                  </Col>
                  <Col className="mt-2" md={6}>
                    <div>
                      <TextInput
                        isDarkMode={isDarkMode}
                        type="text"
                        name="contact_number"
                        placeholder="Enter your Contact"
                        label="Contact Number"
                        onChange={handleChange}
                        value={values?.contact_number}
                        style={{ width: "100%" }}
                      />

                      <span className="error">
                        {errors?.contact_number &&
                          touched?.contact_number &&
                          errors?.contact_number}
                      </span>
                    </div>
                  </Col>
                </Row>

                <div className="mt-2">
                  <TextInput
                    isDarkMode={isDarkMode}
                    name="message"
                    type="text"
                    label="Message"
                    placeholder="Please ensure your description is accurate and thorough to facilitate a more accurate response."
                    onChange={handleChange}
                    value={values?.message}
                    textarea={true}
                    style={{ width: "100%" }}
                  />
                  <span className="error">
                    {errors?.message && touched?.message && errors?.message}
                  </span>
                </div>
              </div>
              <button className="save-info-button mt-4" type="submit">
                <span>Submit</span>
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default Contact;
