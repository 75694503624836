import React from "react";
import "./myaccount.css";
import MyaccountList from "../../components/myaccount-lists/MyaccountList";
import PersonalInfo from "../../components/PersonalInfo/PersonalInfo";
import ShippingAddress from "../../components/ShippingAddress/ShippingAddress";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/slices/authSlice";
const MyAccount = () => {
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout());
  };
  return (
    <>
      {/* <Dashboard /> */}
      <MyaccountList />
      <PersonalInfo />
      <ShippingAddress />
      {/* <Setting /> */}
      <div className="logout-button-section">
        <button className="logout-button" onClick={handleLogout}>
          <span>log out</span>
        </button>
      </div>
    </>
  );
};

export default MyAccount;
