import React from "react";
import Battleicon from "../../assets/images/Battles/battleicon.png";
import battle1 from "../../assets/images/Battles/battle1.png";
import battle2 from "../../assets/images/Battles/battle2.png";
import { useSelector } from "react-redux";
import { routes } from "../../routes";
import { useNavigate } from "react-router-dom";
const BattleEnd = ({ player1, player2 }) => {
  const isDarkMode = useSelector((state) => state.theme.darkMode);
  const navigate = useNavigate();

  return (
    <>
      <div
        className={isDarkMode ? "end-battle-detail-dark" : "end-battle-detail"}
      >
        <h1>THE BATTLE HAS ENDED</h1>
        <div className="mt-4 battle-end-icons">
          <div className="battle-end-icons-details">
            <img
              src={
                player1?.user?.userdetails?.avatar
                  ? `${process.env.REACT_APP_CDN_URL}/images/${player1?.user?.userdetails?.avatar}`
                  : battle1
              }
              alt=""
            />

            <span
              style={{ color: player1?.total_amount > 0 ? "red" : "black" }}
            >
              ${player1?.total_amount}
            </span>
          </div>
          <img src={Battleicon} alt="" />
          <div className="battle-end-icons-details">
            <img
              src={
                player2?.user?.userdetails?.avatar
                  ? `${process.env.REACT_APP_CDN_URL}/images/${player2?.user?.userdetails?.avatar}`
                  : battle2
              }
              alt=""
            />

            <span
              style={{ color: player2?.total_amount > 0 ? "red" : "black" }}
            >
              ${player2?.total_amount}
            </span>
          </div>
        </div>
        <div className="endBattleBtns mt-5">
          <button
            className="backtoBattlebtn p-3"
            onClick={() => navigate(routes.BATTLES)}
          >
            <span>Back to Battle List</span>
          </button>
          {/* <button className="sameBattlebtn">
            <img src={Battleicon} alt="" />
            <span> Create Same Battle for $2.89</span>
          </button> */}
        </div>
      </div>
    </>
  );
};

export default BattleEnd;
