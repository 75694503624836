import Slider from "react-slick";
import React, { useEffect, useState } from "react";
import "./carouselhome.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useRef } from "react";
import { useSelector } from "react-redux";
import arrowback from "../../assets/images/arrowback.png";
import { SliderHome } from "../../api/modules/loothoot.class";
import { ChevronRight } from "lucide-react";

export const SliderComp = () => {
  const sliderRef = useRef();
  const settings = {
    arrows: false,
    dots: false,
    swipeToSlide: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 1,
  };

  const isDarkMode = useSelector((state) => state.theme.darkMode);
  const [sliderDate, setSliderData] = useState();

  useEffect(() => {
    try {
      const api = async () => {
        const res = await SliderHome();

        setSliderData(res?.data?.data);
      };
      api();
    } catch (error) {
      console.log(error);
    }
  }, []);
  return (
    <>
      <div className="sliderMain">
        <Slider {...settings} ref={sliderRef}>
          {sliderDate?.map((item, index) => (
            <>
              <div className="slide-container">
                <a
                  href={item?.link !== null && `https://${item?.link}`}
                  target="_blank" rel="noreferrer"
                >
                  <img
                    src={`${process.env.REACT_APP_CDN_URL}/images/${item?.image}`}
                    alt="no img found"
                  />
                </a>
                {/* {item?.status ? (
                  <div className="sliderContainerDiv">
                    <h1 className="itemText">{item?.title}</h1>
                    <p className="itemDesc" onClick={handleClick}>
                      {item?.description}
                    </p>
                  </div>
                ) : null} */}
              </div>
            </>
          ))}
        </Slider>
      </div>

      <div className="navigation-buttons-main">
        <div
          onClick={() => sliderRef.current.slickPrev()}
          className={
            isDarkMode
              ? "productslider-prev-button slider-prev-button-dark"
              : "productslider-prev-button slider-prev-button-light"
          }
        >
          <img src={arrowback} alt="" />
        </div>

        <div
          onClick={() => sliderRef.current.slickNext()}
          className="productslider-next-button"
        >
          <ChevronRight style={{ color: "white" }} />
        </div>
      </div>
    </>
  );
};
