import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { clearMessage } from "../../redux/slices/messageSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Toaster = ({ message }) => {
  const dispatch = useDispatch();
  // const notify = (msg) => toast(msg);

  useEffect(() => {
    if (message?.error) {
      toast.error(message?.error, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
      });
    } else if (message?.success) {
      toast.success(message?.success, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
      });
    }
    setTimeout(() => dispatch(clearMessage()), 2000);
  }, [message]);
  return <ToastContainer />;
};

export default Toaster;
